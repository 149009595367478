import React /*, {useContext}*/ from 'react'
import styled /*, { ThemeContext }*/ from 'styled-components'
import ToolTip from '../../components/ToolTip'
import Media from '../../theme/media-breackpoint'

export default function IFOHome() {
  //   const theme = useContext(ThemeContext)

  return (
    <BinaceSwap>
      <ContainerGrid>
        <BinanceStitle01>DECENTRALIZED POST DEX OFFERINGS</BinanceStitle01>
        <BinanceTitle02>PDO designed as secondary offering and liquidity mining alternative</BinanceTitle02>
        <IfoInfoBoxM>
          <IfoInfoBoxS03 className="brFixed">
            <IfoInfoTitle01>How to launch your own PDO</IfoInfoTitle01>
            <IfoInfoTitle002>
              <BxNo>1</BxNo>
              <Span>
                Connect your AMM liquidity pool from Uniswap, Sushiswap, Pancakeswap or anywhere else{' '}
                <ToolTip tip={'100% free for limited time'} id={'tipmininv'} />
              </Span>
            </IfoInfoTitle002>
            <IfoInfoTitle002>
              <BxNo>2</BxNo>
              <Span>Set up your PDO terms and deposit tokens for APY reward</Span>
            </IfoInfoTitle002>
            <IfoInfoTitle002>
              <BxNo>3</BxNo>
              <Span>
                The PDO is free to launch but charges a 2.5% fee{' '}
                <ToolTip tip={'Charges a variable fee. Not set at 2.5% always'} id={'tipmininv'} />
              </Span>
            </IfoInfoTitle002>
            <IfoInfoTitle002>
              <BxNo>4</BxNo>
              <Span>
                The smart contract automatically collects and distributes funds and tokens to users, to you and to your
                liquidity pool{' '}
              </Span>
            </IfoInfoTitle002>
            <IdoCreatBTN href="/#/pdolaunch" >
              <JwbTrophyIco> LAUNCH YOUR PDO </JwbTrophyIco>
            </IdoCreatBTN>
          </IfoInfoBoxS03>
          <IfoInfoBoxS03>
            <IfoInfoTitle01>How to invest in PDO</IfoInfoTitle01>
            <IfoInfoTitle002>
              <BxNo>1</BxNo>
              <Span>
                Choose if you want to invest directly into the PDO and get the current tokens price plus the APY reward,
                or if you want to utilize the secure future, secure the APY reward based on today’s price but finalize
                your decision later on
              </Span>
            </IfoInfoTitle002>
            <IfoInfoTitle002>
              <BxNo>2</BxNo>
              <Span>
                It will increase the token value in the market by buying tokens from the pool and include that investors
                will pay 2.5% fee to invest but will get refunded 100% of the fees + gas costs{' '}
              </Span>
            </IfoInfoTitle002>
            <IfoInfoTitle002>
              <BxNo>3</BxNo>
              <Span>
                At the end of the staking period you will be required to claim the tokens plus the APY reward to your
                wallet or to the dumper shield (depends on the PDO terms)
              </Span>
            </IfoInfoTitle002>

            <IdoCreatBTN href="/#/list">
              <JwbTrophyIco>INVEST IN PDO </JwbTrophyIco>
            </IdoCreatBTN>
          </IfoInfoBoxS03>
        </IfoInfoBoxM>
      </ContainerGrid>
    </BinaceSwap>
  )
}
const Circle = styled.i`
  font-size: 13px;
  position: relative;
  top: -11px;
  width: auto !important;
`
const JwbTrophyIco = styled.span`
  display: inline-block;
  position: relative;
  top: auto;
  margin: 0 auto;
`
const IdoCreatBTN = styled.a`
  font: bold 30px/28px 'IBM Plex Mono';
  background: none;
  color: #fff;
  border: 2px solid #00f02b;
  box-shadow: 0 0 15px #00f02b;
  padding: 26px 80px;
  text-align: center;
  border-radius: 10px;
  width: 100%;
  text-align: center;
  display: flex;
  cursor: pointer;
  margin-top: auto;
  ${Media.xs} {
    padding: 20px 20px;
    font: bold 20px/22px 'IBM Plex Mono';
  }
`
const IfoInfoTitle002 = styled.div`
  position: relative;
  color: ${({ theme }) => theme.ifoText1};
  margin: 0 0 33px 0;
  padding-left: 52px;
  line-height: 22px;
  font-weight: 700;
  font-size: 18px;
  padding-top: 5px;
  /* min-height: 62px; */
  width: 100%;
  text-align: left;
  ${Media.xs} {
    padding-right: 5px;
  }
  span {
    line-height: 24px;
  }
`

const IfoInfoTitle002V2 = styled(IfoInfoTitle002)`
  width: 100%;
`
const IfoInfoBoxS03 = styled.div`
  width: 50%;
  padding: 0 60px;
  color: #000000;
  text-align: center;
  display: flex;
  align-items: flex-start;
  flex-flow: column;
  ${Media.sm} {
    width: 100%;
    padding: 0 0 15px 0;
  }
  &.brFixed {
    border-right: 1px solid #5a5e67;
    ${Media.sm} {
      width: 100%;
      padding: 0 0 15px 0;
      border-right: none;
    }
  }
`
const BinaceSwap = styled.section`
  width: 100%;
  display: flex;
  /* height: 100%; */
  min-height: calc(100vh - 100px);
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  padding-top: 140px;
  ${Media.xs} {
    padding-top: 80px;
  }
`
const Trophy = styled.img``
const ContainerGrid = styled.div`
  padding: 0 20px;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
`
const BinanceStitle01 = styled.div`
  color: ${({ theme }) => theme.ifoText1};
  text-align: center;
  font-weight: 700;
  margin: 0 0 8px 0;
  font: 700 54px/60px 'Kanit', monospace, arial;
  text-shadow: 0 0 8px currentColor;
  position: relative;
  :after {
    content: '';
    position: absolute;
    top: auto;
    bottom: 2px;
    left: calc(50% - 57px);
    width: 126px;
    height: 3px;
    background-color: #00f02b;
  }
  @media (max-width: 1399px) and (min-width: 1200px) {
    font-size: 30px;
    line-height: 45px;
  }

  ${Media.sm} {
    font: 700 36px/40px 'Kanit', monospace, arial;
    margin: 20px 0 30px 0;
  }
  ${Media.xs} {
    font: 700 22px/28px 'Kanit', monospace, arial;
    margin: 20px 0 10px 0;
  }
`
const BinanceTitle02 = styled.div`
  color: ${({ theme }) => theme.ifoText5};
  font-size: 18px;
  line-height: 40px;
  margin: 0 0 45px 0;
  text-align: center;
  font-weight: 400;
  @media (max-width: 500px) and (min-width: 320px) {
    font-size: 16px;
    line-height: 24px;
    padding: 0 15px;
  }
  @media (max-width: 991px) and (min-width: 768px) {
    font-size: 20px;
  }
`

const IfoInfoBoxM = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-wrap: wrap;
  margin: 60px -60px 100px;
`
// const IfoInfoBoxS01 = styled.div`
//     width: 50%;
//     padding-right: 40px;
//     border-right: 1px solid #d6d6d6;
//     color: ${({ theme }) => theme.ifoText1};
// `
const IfoInfoTitle01 = styled.div`
  font: 700 36px/30px 'Kanit', arial;
  color: ${({ theme }) => theme.ifoText1};
  margin: 0 0 45px 0;
  text-align: left;
  width: 100%;

  ${Media.xs} {
    font: 700 24px/30px 'Kanit', arial;
  }
`
// const IfoInfoTitle02 = styled.div`
//     position: relative;
//     color: ${({ theme }) => theme.ifoText1};
//     margin: 0 0 45px 0;
//     padding-left: 52px;
//     font-weight: 700;
//     font-size: 18px;
//     padding-top: 5px;
//     min-height: 62px;

// `

const BxNo = styled.i`
  font: 700 24px/33px 'Kanit', arial;
  color: #fff;
  background-color: #00f02b;
  position: absolute;
  min-width: 33px;
  text-align: center;
  margin: 0 0 45px 0;
  left: 0;
  top: 0px;
  padding: 0 6px;
`
const Span = styled.span`
  color: ${({ theme }) => theme.ifoText1};
  display: block;
  font-weight: 400;
  font-size: 14px;
  margin: 0px 0 0 0;
`
// const SpanFix01 = styled.span`
//     color: ${({ theme }) => theme.ifoText1};
//     display: block;
//     font-weight: 400;
//     font-size: 14px;
//     margin:0 0 0 0;
// `
// const Button = styled.a`
//     display: inline-block;
//     font: normal 12px/40px 'Kanit', arial, sans-serif;
//     background-color: #f3ba2f;
//     color: #fff;
//     padding: 10px 17px;
//     border-radius: 10px;
//     :hover{
//         background-color: #dca213;
//     }
// `

// const JwbDollarIco = styled.i`
//     width: 28px;
//     height: 28px;
//     background: url(../images/dollar_icon.png) 50% 50% no-repeat;
//     display: inline-block;
//     position: relative;
//     top: 8px;
// `

// const IfoInfoBoxS02 = styled.div`
//     width: 50%;
//     padding-left: 40px;
//     color: ${({ theme }) => theme.ifoText1};
// `
// const IfoInfoTitle03 = styled.div`
//     position: relative;
//     color: ${({ theme }) => theme.ifoText1};
//     margin: 0 0 50px 0;
//     padding-left: 52px;
//     font-weight: 700;
//     font-size: 18px;
//     padding-top: 5px;
//     min-height: 62px;
// `
