import React, { useState, useEffect, useRef } from 'react'
// import { useTranslation } from 'react-i18next'
import styled /*, { ThemeContext }*/ from 'styled-components'
import { useActiveWeb3React } from '../../hooks'
import TrophyImage from '../../assets/images/trophy_icon.png'
import TimerbgImage from '../../assets/images/timerBGBack.png'
import { useETHBalances } from '../../state/wallet/hooks'
// import IFOCard from '../../components/IFOCard'
import IFOFilter from '../../components/IFOFilter'
import InactiveIFO from '../../components/InactiveIFO'
import ActiveIFO from '../../components/ActiveIFO'
import ComingSoonIFO from '../../components/ComingSoonIFO'
import Media from '../../theme/media-breackpoint'
import { API_BASEURL } from '../../constants'

import axios from 'axios'
import { Collapse } from 'react-collapse'
import Tooltip from '../../components/ToolTip'
import { convertToCurrency } from '../../utils/numberConveter'
import { useQuery } from '../../hooks/useQuery'
import { useWalletModalToggle } from '../../state/application/hooks'
import { useAlert } from 'react-alert'
export interface IFOListProps {
  search: string
  symbol: string
  apy: string
  period: string
}

const api = axios.create({
  baseURL: API_BASEURL
})

export default function IFOList(props) {
  const query = useQuery()
  // const socketUrl = 'wss://stream.binance.com:9443/ws/bnbusdt@ticker';
  const [price, setPrice] = useState(0.0)

  const { account, chainId } = useActiveWeb3React()
  const toggleWalletModal = useWalletModalToggle()
  const alert = useAlert()

  const [isOpened, setIsOpened] = useState(false)
  const [refercode, setRefercode] = useState('')

  const [totalInvestment, setTotalInvestment] = useState<number>(0)
  const [search, setSearch] = useState('')
  const [symbol, setSybmol] = useState('')
  const [apy, setApy] = useState('')
  const [period, setPeriod] = useState('')

  const [isLicenceOpen, setIsLicenceOpen] = useState(false)
  const refferLink = useRef(null)

  const [viewType, setViewType] = useState(1)
  // const [bSWAPBalance, setBSWAPBalance] = useState('')
  const bnbbalance = useETHBalances([account])[account]
  const handleSearchChange = event => {
    setSearch(event.target.value)
    // setViewType(1)
  }

  const [data, setData] = useState([])
  const [update, setUpdate] = useState(false)
  // const[updatePrice, setUpdatePrice] = useState(false)

  const refresh = () => {
    if (window['transactionPending']) {
      return
    }
    const filter = {}
    if (window['isLicenses']) {
      if (window['LicensesConfig']['pdoDisplay'] === 'SINGLE') {
        filter['liquidation.licenseNumber'] = window['Licenses']['licenseNumber']
      }
    }

    api
      .post(`/getpdo`, {
        filter: filter
      })
      .then(response => {
        setData(response.data.data)
        setPrice(response.data.bnbPrice)
      })
    api.get(`/getTotalInvestment`).then(response => {
      setTotalInvestment(response.data.totalInvestment+9108)
    })
  }

  useEffect(() => {
    if (account === null) {
      return
    }
    api.get(`/getReferralForAddress/${account}`).then(response => {
      setRefercode(response.data.data.uniqueId)
    })
  }, [account])

  const copyToClipboard = () => {
    navigator.clipboard.writeText(`https://app.pdo.finance/#/list/${refercode}`)
    alert.success('copied!')
  }

  useEffect(() => {
    refresh()
    setInterval(refresh, 10000)
  }, [])

  const raisedAmount = convertToCurrency(Number(totalInvestment))

  const retunRaisedAmount = () => {
    const displayAmount = []

    if (raisedAmount === 'NaN') {
      displayAmount.push(<span>0</span>)
      displayAmount.push(<span>0</span>)
      displayAmount.push('.')
      displayAmount.push(<span>0</span>)
      displayAmount.push(<span>0</span>)
      return displayAmount
    }

    for (let index = 0; index < raisedAmount.length; index++) {
      if (raisedAmount[index] === ',' || raisedAmount[index] === '.') displayAmount.push(raisedAmount[index])
      else displayAmount.push(<span>{raisedAmount[index]}</span>)
    }
    return displayAmount
  }

  const createPdoLink = () => {
    let link = `/#/pdolaunch`
    console.log("refercode",props.match.params.refercode)

    if(props.match.params.refercode !== undefined)
      link += `/${props.match.params.refercode}/`

    link += '?'
    if(query.get('preview') !== null)
      link += 'preview=true&'
    
    if(query.get('ref') !== null)
      link +=`ref=${query.get('ref')}&`


    return link;
  
  }

  return (
    <Section id="startSection">
      <GridContainer>
        <BinanceTitle01>DECENTRALIZED POST DEX OFFERINGS</BinanceTitle01>
        <BinanceTitle02>
          ScalePad to boost existing projects
          <Tooltip
            tip={
              'Investors buy tokens at a discount, with 0 slippage and opportunity cost and stake then for reward with Dumper Shield protection. Existing projects sell tokens to raise funds for operations, cash out or grow liquidity without negatively affecting the market price.'
            }
            id={'m1'}
          />
          {/* <Circle> <HelpCircles href="/#/home"> <i className="fas fa-question-circle"></i></HelpCircles>
                    </Circle> */}
        </BinanceTitle02>

        <BinanceTitle03>Total Fundraising</BinanceTitle03>

        <BinanceFundRise>
          <span>$</span>
          {retunRaisedAmount()}
          {/* <span>3</span>
                    ,
                    <span>0</span>
                    <span>5</span>
                    <span>2</span>
                    ,
                    <span>5</span>
                    <span>3</span>
                    <span>6</span>
                    .
                    <span>6</span>
                    <span>5</span> */}
        </BinanceFundRise>

        <SearchBox>
          <SearchBoxLeft>
            <SearchBoxLeftTitle>
              <i className="fas fa-search"></i> Search by
            </SearchBoxLeftTitle>
            <SearchBoxLeftInput>
              <SearchBoxLeftInputBox
                type="text"
                value={search}
                readOnly={true}
                onChange={handleSearchChange}
                placeholder="token symbol, type of PDO, APY..."
              ></SearchBoxLeftInputBox>
            </SearchBoxLeftInput>
            {
              <FilterIcon
                onClick={() => {
                  setIsOpened(!isOpened)
                }}
                style={{ color: `${isOpened ? '#00f02b' : ''}` }}
              >
                <i className="fas fa-filter"></i>
              </FilterIcon>
            }
          </SearchBoxLeft>
          <SearchBoxRight>
            <LaunchYourInfoButton
              href={createPdoLink()}
              className="changeBtn"
            >
            <span>LAUNCH YOUR PDO</span>
            </LaunchYourInfoButton>
          </SearchBoxRight>
          <div style={{ width: '100%' }}>
            <Collapse isOpened={isOpened}>
              <IFOFilter
                symbol={symbol}
                apy={apy}
                period={period}
                viewType={viewType}
                setSymbol={setSybmol}
                setApy={setApy}
                setPeriod={setPeriod}
                setViewType={setViewType}
              />
            </Collapse>
          </div>
        </SearchBox>

        <ActiveIFO
          data={data}
          balance={bnbbalance?.toSignificant(4)}
          refresh={refresh}
          price={price}
          viewType={viewType}
        />

        <ComingSoonIFO data={data} balance={bnbbalance?.toSignificant(4)} viewType={viewType} price={price} />

        <InactiveIFO
          data={data}
          balance={bnbbalance?.toSignificant(4)}
          price={price}
          viewType={viewType}
          refresh={refresh}
        />
        {account === null ? (
          <BOuter>
            <CBAButton onClick={toggleWalletModal}>Connect Your Wallet To Become An Affiliate</CBAButton>
            <GainText>Gain 1.25% fee from each new launched PDO</GainText>
          </BOuter>
        ) : (
          <AfflMBX>
            <AfflSbx01>
              <input
                ref={refferLink}
                type="text"
                value={`https://app.pdo.finance/#/list/${refercode}`}
                readOnly={true}
              />
              <button className="AFFBTN02" onClick={() => copyToClipboard()}>
                COPY
              </button>
            </AfflSbx01>
            <GainText>Gain 1.25% fee from each new launched PDO</GainText>
          </AfflMBX>
        )}
        {/* <PDOLicence  /> */}
        {/* <SideBar /> */}
        {/* <Popup /> */}
      </GridContainer>
    </Section>
  )
}

const UnderLine = styled.span`
  border-bottom: 2px solid #00f02b;
`

const BOuter = styled.div`
  text-align: center;
  padding: 60px 0 30px 0;
`

const CBAButton = styled.button`
  font-size: 12px;
  font-weight: normal;
  color: ${({ theme }) => theme.ifoText5};
  margin-bottom: 15px;
  padding: 11px 19px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.ifoBg1};
  border: 1px solid ${({ theme }) => theme.border01};
  :hover {
    background-color: ${({ theme }) => theme.ifoText5};
    color: ${({ theme }) => theme.text1};
  }
`

const GainText = styled.div`
  font-size: 11px;
  font-weight: normal;
  color: ${({ theme }) => theme.ifoText5};
  i {
    top: -6px;
    right: -3px;
    font-size: 10px;
  }
`

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
`
const AfflMBX = styled(FlexDiv)`
  max-width: 360px;
  margin: 40px auto 20px auto;
  flex-direction: column;
  font-size: 12px;
  color: #8e9195;
  button.AFFBTN01 {
    width: 100%;
    font-size: 12px;
    color: #8e9195;
    background: transparent;
    border: 1px solid #8e9195;
    padding: 8px 10px;
    border-radius: 4px;
    margin-bottom: 12px;
    :hover {
      color: #00f02b;
      border-color: #00f02b;
    }
  }
  span {
    position: relative;
    i {
      top: -5px;
    }
  }
`
const AfflSbx01 = styled(FlexDiv)`
  flex-direction: row;
  margin-bottom: 15px;
  input {
    font-size: 12px;
    width: calc(100% - 78px);
    color: #8e9195;
    background: transparent;
    border: 1px solid #8e9195;
    padding: 8px 10px;
    border-radius: 4px;
  }
  button.AFFBTN02 {
    width: 70px;
    font-size: 12px;
    color: #fff;
    background: #00f02b;
    padding: 8px 10px;
    border-radius: 4px;
    border: 0px;
    margin-left: 8px;
    :hover {
      background: #03d729;
    }
  }
  ${Media.xs} {
    padding-left: 20px;
    padding-right: 20px;
  }
`

const Section = styled.section`
  width: 100%;
  display: flex;
  /* height: 100%; */
  min-height: calc(100vh - 100px);
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  padding-top: 160px;
  @media (max-width: 767px) {
    padding-top: 73px;
  }
`
const GridContainer = styled.div`
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
`

const BinanceTitle01 = styled.div`
  color: ${({ theme }) => theme.ifoText1};
  text-align: center;
  font-weight: 700;
  margin: 0 0 10px 0;
  font: 700 54px/60px 'Kanit', monospace, arial;
  position: relative;
  text-shadow: 0 0 8px currentColor;
  :after {
    content: '';
    position: absolute;
    top: auto;
    bottom: 2px;
    left: calc(50% - 57px);
    width: 126px;
    height: 3px;
    background-color: #00f02b;
  }

  @media (max-width: 991px) and (min-width: 768px) {
    font-size: 30px;
    line-height: 45px;
    padding: 0 20px;
  }

  ${Media.sm} {
    font: 700 36px/40px 'Kanit', monospace, arial;
    margin: 20px 0 30px 0;
  }
  ${Media.xs} {
    font: 700 27px/32px 'Kanit', monospace, arial;
  }
`
const BinanceTitle02 = styled.div`
  color: ${({ theme }) => theme.ifoText5};
  font-size: 18px;
  line-height: 30px;
  margin: 0 0 45px 0;
  text-align: center;
  font-weight: 400;
  a {
    color: ${({ theme }) => theme.ifoText5};
  }
  @media (max-width: 500px) and (min-width: 320px) {
    font-size: 16px;
    line-height: 24px;
    padding: 0 15px;
  }
  @media (max-width: 991px) and (min-width: 768px) {
    font-size: 20px;
  }
`

const BinanceTitle03 = styled.div`
  color: #34373d;
  font: 700 24px/18px 'Kanit', monospace, arial;
  margin: 60px auto 45px auto;
  width: 100%;
  max-width: 955px;
  text-align: left;

  @media (max-width: 767px) {
    font-size: 22px;
    line-height: 24px;
    margin: 40px auto 30px auto;
    padding: 0 15px;
  }
  @media (max-width: 991px) and (min-width: 768px) {
    font-size: 20px;
  }
`

const BinanceFundRise = styled.div`
  font: 400 80px/60px 'Kanit', monospace, arial;
  margin: 20px 0 70px 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #00f02b;

  span {
    background: url(${TimerbgImage}) 50% 50% no-repeat;
    background-size: cover;
    width: 82px;
    height: 98px;
    margin: 0 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: 2px solid #5a5e67;
    color: #00f02b;

    @media (max-width: 991px) {
      width: 58px;
      height: 78px;
    }
    @media (max-width: 767px) {
      width: 50px;
      height: 54px;
    }
    @media (max-width: 500px) {
      height: 33px;
      margin: 0 3px;
      border-radius: 4px;
    }
  }

  @media (max-width: 500px) and (min-width: 320px) {
    font-size: 16px;
    line-height: 24px;
    padding: 0 15px;
  }
  @media (max-width: 767px) and (min-width: 501px) {
    font-size: 28px;
    line-height: 24px;
    padding: 0 15px;
  }
  @media (max-width: 991px) and (min-width: 768px) {
    font-size: 46px;
  }
`

const SearchBox = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 20px 15px;
  margin: 10px 0;
  background-color: transparent;
  border-radius: 0;
  border-bottom: 2px solid #5a5e67;
  border-top: 2px solid #5a5e67;
`

const SearchBoxLeft = styled.div`
  width: calc(100% - 336px);
  display: flex;
  padding-right: 20px;
  @media (max-width: 767px) {
    width: 100%;
    padding-right: 0;
    margin-bottom: 20px;
    flex-wrap: wrap;
  }
  @media (max-width: 991px) and (min-width: 768px) {
    width: 100%;
  }
`
const SearchBoxLeftTitle = styled.div`
  color: ${({ theme }) => theme.ifoText1};
  font-size: 18px;
  width: 145px;
  i {
    margin-right: 10px;
  }
  @media (max-width: 500px) and (min-width: 320px) {
    font: normal 15px/30px 'Kanit', arial, sans-serif;
    /* color: #fff; */
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
  }
  @media (max-width: 767px) and (min-width: 501px) {
    font: normal 18px/30px 'Kanit', arial, sans-serif;
    white-space: nowrap;
    width: 112px;
    overflow: hidden;
  }
  @media (max-width: 991px) and (min-width: 768px) {
    font-size: 20px;
    width: 190px;
  }
`
const SearchBoxLeftInput = styled.div`
  width: calc(100% - 185px);
  @media (max-width: 500px) and (min-width: 320px) {
    width: calc(100% - 20px);
  }
  @media (max-width: 767px) and (min-width: 501px) {
    width: calc(100% - 112px);
  }
  @media (max-width: 991px) and (min-width: 768px) {
    width: calc(100% - 190px);
  }
`
const SearchBoxLeftInputBox = styled.input`
  color: ${({ theme }) => theme.ifoText3} !important;
  font-size: 18px;
  /* color: #fff; */
  background: transparent;
  /* outline: none; */
  border: 0px;
  padding-left: 4px;
  width: 100%;
  @media (max-width: 767px) {
    font: normal 18px/30px 'Kanit', arial, sans-serif;
    /* color: #fff; */
    background: 0 0;
    /* outline: 0; */
    border: 0;
    padding-left: 4px;
    width: 100%;
  }
  @media (max-width: 500px) {
  }
  @media (max-width: 991px) and (min-width: 768px) {
    font-size: 20px;
  }

  ::placeholder {
    /* color:#fff;  */
    color: ${({ theme }) => theme.ifoText3};
    opacity: 1;
  }
  :-ms-input-placeholder,
  ::-ms-input-placeholder {
    color: ${({ theme }) => theme.ifoText3};
    /* color:#fff;  */
  }
`

const FilterIcon = styled.div`
  /* color: #656565; */
  color: ${({ theme }) => theme.text1};
  font-size: 18px;
  display: inline-block;
  cursor: pointer;
  :hover {
    color: #00f02b;
  }
`

const SearchBoxRight = styled.div`
  width: 322px;
  border-left: 1px solid #393d46;
  text-align: right;
  padding-left: 18px;
  a {
    padding: 15px 17px;
  }
  @media (max-width: 500px) and (min-width: 320px) {
    padding-left: 0;
  }
  @media (max-width: 767px) {
    padding-left: 0;
    width: 100%;
    border: 0px;
  }
  @media (max-width: 991px) and (min-width: 768px) {
    width: 60%;
    border: 0;
    text-align: center;
    margin: 10px auto 2px auto;
  }
`

const LaunchYourInfoButton = styled.a`
  display: block;
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  background-color: none;
  border: 2px solid #00f02b;
  text-align: center;
  color: #00f02b;
  box-shadow: 0 0 8px #00f02b;
  padding: 10px 17px;
  border-radius: 10px;
  padding-left: 20px;
  :hover {
    background-color: #00f02b;
    color: #fff;
  }
  // :hover span{
  //     display:none;
  // }
  // :hover ::before{
  //     content:"Coming Soon";
  // }
  @media (max-width: 500px) and (min-width: 320px) {
    display: block;
    font: 700 21px/31px 'Kanit', arial, sans-serif;
    padding: 10px 15px;
    border-radius: 10px;
  }
  @media (max-width: 991px) and (min-width: 768px) {
    font: 700 30px/40px 'Kanit', arial, sans-serif;
  }
`

const LaunchYourIFOImage = styled.i`
  width: 28px;
  height: 28px;
  background: url(${TrophyImage}) 50% 50% no-repeat;
  display: inline-block;
  position: relative;
  top: 8px;
`

const HelpCircles = styled.a`
  font-size: 12px;
  font-weight: 900;
  font-family: 'Font Awesome 5 Free';
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  color:#f8f1e3 !important &:before {
    content: '\f059';
  }
  &:hover {
    color: #f8f1e3 !important;
  }
`
const Circle = styled.i`
  font-size: 13px;
  position: relative;
  top: -11px;
  width: auto !important;
`
