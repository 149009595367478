import React, { PureComponent, useCallback, useState } from 'react'
import { useActiveWeb3React } from '../../hooks'
// import Web3Status from '../Web3Status'
import { useWalletModalToggle } from '../../state/application/hooks'
import styled, { css, ThemeContext } from 'styled-components'
import IFOCounter from '../IFOCounter'
import { Collapse } from 'react-collapse'
import ToolTip from '../ToolTip'
import bnbICON from '../../assets/images/bnbICON.png'
import ethICON from '../../assets/images/ethICON.png'
import defaultImage from '../../assets/images/default.png'
import CoinICO from '../../assets/images/coinIco-04.png'
import CloseIMG from '../../assets/images/close-btn.png'
import ReactTooltip from 'react-tooltip'
import InputRange from 'react-input-range'
import Media from '../../theme/media-breackpoint'
import { calculateGasMargin, escapeRegExp, getEtherscanLink } from '../../utils'
import TransactionConfirmationModal from '../TransactionConfirmationModal'
import { useOrderContract, usePDOContract } from '../../hooks/useContract'

import ConfirmTxPopup from '../ConfirmTxPopup'
import { useAlert } from 'react-alert'
import { ethers } from 'ethers'
import { ORDER_BOOK_ADDRESSES, ORDER_BOOK_INTERFACE, PDO_MAIN_INTERFACE } from '../../constants/pdo'
import {
  convertToCurrency,
  convertToCurrencyFourDecimal,
  convertToPercantage,
  convertToToken,
  convertToTokenFourDecimal
} from '../../utils/numberConveter'
import { FutureCancelConfimation, FutureExcuteConfimation } from '../FuturePopUp'
import web3Js from 'web3'
import { RefreshCw } from 'react-feather'

interface FutureBarProps {
  data
  orders
  isFutureBarOpen: boolean
  setIsFutureBarOpen: Function
  balance
  price
  userUsdLimit: number
  userTokenLimit: number
  setPopUpMsg: Function
  setIsTxFailed: Function
  setTxPopUp: Function
  tokenLogo
  calculateMaxToken: Function
}
const inputRegex = RegExp(`^\\d*(?:\\\\[.])?\\d*$`) // match escaped "." characters via in a non-capturing group

export default function FutureBar({
  data,
  orders,
  isFutureBarOpen,
  setIsFutureBarOpen,
  balance,
  price,
  userUsdLimit,
  userTokenLimit,
  setPopUpMsg,
  setIsTxFailed,
  setTxPopUp,
  tokenLogo,
  calculateMaxToken
}: FutureBarProps) {
  const alert = useAlert()

  const { account, chainId } = useActiveWeb3React()
  const [startMark, setStartMark] = useState<boolean>(false)
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [txHash, setTxHash] = useState<string>('')

  const [amount, setAmount] = useState<string>('')
  const [tokenGet, setTokenGet] = useState<number>(0)

  const [cancelOrderFlag, setCanceOrderFlag] = useState<boolean>(false)
  const [excuteOrderFlag, setExcuteOrderFlag] = useState<boolean>(false)
  const [excuteOrderId, setExcuteOrderId] = useState<number>(0)
  const [excuteOrderPurachsePrice, setExcuteOrderPurachsePrice] = useState<number>(0)

  const pdoContract = usePDOContract(data.pdoAddress)

  const addDefaultSrc = ev => {
    ev.target.src = defaultImage
  }

  const enforcer = (nextUserInput: string) => {
    if (nextUserInput === '' || inputRegex.test(escapeRegExp(nextUserInput))) {
      setAmount(nextUserInput)

      const usdAmount = Number(nextUserInput) * price
      setTokenGet(usdAmount / data.offeringTokenPrice)
    }
  }

  const handleDismissConfirmation = useCallback(() => {
    if (txHash !== '') {
      return
    }
    setShowConfirm(false)
    // if there was a tx hash, we want to clear the input
  }, [])

  const tokenImage = ''

  const capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  const handleInvestment = () => {
    if (amount === '' || amount === '0') {
      alert.show('Please enter valid amount')
      return
    }

    depositBNB(Number(amount), true)
  }

  const toWei = (val: string): string => {
    const wei = ethers.utils.parseEther(val)
    return wei.toString()
  }

  const closePopupInSuccess = () => {
    setStartMark(true)
    setShowConfirm(false)
    setStartMark(false)
    setTxHash('')
    window['transactionPending'] = false
  }

  const depositBNB = async (bnbAmount: number, isFuture: boolean) => {
    const UsdAmount = price * bnbAmount

    console.log(price, bnbAmount)
    if (data.investInfo.limitType === 'USD_LIMIT') {
      if (userUsdLimit + UsdAmount < data.investInfo.minInvest) {
        alert.show(`You have to invest minimum ${data.investInfo.minInvest} Usd`)
        return
      }

      if (userUsdLimit + UsdAmount > data.investInfo.maxInvest) {
        alert.show(`You have maximum Limit of ${data.investInfo.maxInvest - userUsdLimit} Usd`)
        return
      }
    }

    if (data.investInfo.limitType === 'TOKEN_LIMIT') {
      if (userTokenLimit + tokenGet < data.investInfo.minInvest) {
        alert.show(`You have to purchase minimum ${data.investInfo.minInvest} ${data.tokenInfo.symbol.toUpperCase()}`)
        return
      }

      if (userTokenLimit + tokenGet > data.investInfo.maxInvest) {
        alert.show(
          `You can purchase maximum ${data.investInfo.maxInvest -
            userTokenLimit} ${data.tokenInfo.symbol.toUpperCase()}`
        )
        return
      }
    }

    const amountB = bnbAmount + (bnbAmount * (data.liquidation.companyFee + 0.0001)) / 100

    if (parseFloat(amountB.toString()) > parseFloat(balance)) {
      alert.show('You dont have enough balance')
      return
    }

    if (pdoContract !== null) {
      let onError = false
      try {
        setShowConfirm(true)
        const amt = toWei(amountB.toString())
        const investAmount = toWei(bnbAmount.toString())
        const estimatedGas = await pdoContract.estimateGas.invest(investAmount, isFuture, { value: amt })
        const txResponse = await pdoContract
          .invest(investAmount, isFuture, {
            gasLimit: calculateGasMargin(estimatedGas),
            value: amt
          })
          .catch(() => {
            onError = true
            setShowConfirm(false)
          })
        setTxHash(txResponse.hash)
        window['transactionPending'] = true
        if (onError === false) {
          const txReceipt = await txResponse.wait()
          setIsTxFailed(txReceipt.status === 1 ? false : true)
          let isRefund = false
          if (txReceipt.status === 1) {
            let eventData
            const events = txReceipt.events
            for (let i = 0; i < events.length; i++) {
              const ev = events[i]
              if (ev.event === 'EthRefunded') {
                eventData = PDO_MAIN_INTERFACE.parseLog(ev).args
                isRefund = true
              }
            }

            if (isRefund) {
              const refund = web3Js.utils.fromWei(eventData.amount._hex)
              const message = (
                <span style={{ fontSize: '20px' }}>
                  Your
                  <span style={{ color: 'white' }}>{bnbAmount}</span>
                  BNB has been successfully received but exceeds the maximum amount available to invest. The smart
                  contract is refunding
                  <span style={{ color: 'white' }}>{refund}</span>
                  BNB back to your wallet.
                  <p>
                    <a href={getEtherscanLink(chainId, txHash, 'transaction')} target="_blank" rel="noreferrer">
                      See Proof
                    </a>
                  </p>
                </span>
              )
              setPopUpMsg(message)
            } else {
              setPopUpMsg('You succesfully secured the APY reward')
            }
            setAmount('')
            closePopupInSuccess()
            setTxPopUp(true)
          } else {
            setShowConfirm(false)
            setPopUpMsg('Your investment is failed!')
            setAmount('')
            setTxHash('')
            setTxPopUp(true)
          }
        }
      } catch (error) {
        console.log('error', error)
        onError = true
        setShowConfirm(false)
        alert.show('Some error occur in deposit')
      }
    }
  }

  // excuteFuture

  const excuteFuture = async orderId => {
    if (pdoContract !== null) {
      let onError = false
      try {
        setShowConfirm(true)
        const estimatedGas = await pdoContract.estimateGas.excuteFuture(orderId)
        const txResponse = await pdoContract
          .excuteFuture(orderId, {
            gasLimit: calculateGasMargin(estimatedGas)
          })
          .catch(() => {
            onError = true
            setShowConfirm(false)
          })
        setTxHash(txResponse.hash)

        if (onError === false) {
          const txReceipt = await txResponse.wait()
          setIsTxFailed(txReceipt.status === 1 ? false : true)
          if (txReceipt.status === 1) {
            setPopUpMsg('Your investment is successfully received')
          } else {
            setPopUpMsg('Your transcation is failed!')
            setTxHash('')
          }
          closePopupInSuccess()
          setTxPopUp(true)
        }
      } catch (error) {
        //console.log(error.message)
        onError = true
        setShowConfirm(false)
        console.log(error)
        alert.show('Some error occur')
      }
    }
  }

  const cancelFuture = async orderId => {
    if (pdoContract !== null) {
      let onError = false
      try {
        setShowConfirm(true)

        const estimatedGas = await pdoContract.estimateGas.cancelFuture(orderId)
        const txResponse = await pdoContract
          .cancelFuture(orderId, {
            gasLimit: calculateGasMargin(estimatedGas)
          })
          .catch(() => {
            onError = true
            setShowConfirm(false)
          })
        setTxHash(txResponse.hash)
        if (onError === false) {
          const txReceipt = await txResponse.wait()
          setIsTxFailed(true)
          if (txReceipt.status === 1) {
            setPopUpMsg('You succesfully canceled your investment')
          } else {
            setPopUpMsg('Your transcation is failed!')
            setTxHash('')
          }
          closePopupInSuccess()
          setTxPopUp(true)
        }
      } catch (error) {
        //console.log(error.message)
        onError = true
        setShowConfirm(false)
        console.log(error)
        alert.show('Some error occur Cancellation')
      }
    }
  }

  const excuteFutureCB = (isExcuted, orderId) => {
    setExcuteOrderFlag(false)
    if (isExcuted) {
      excuteFuture(orderId)
    }
  }

  const cancelFutureCB = (isExcuted, orderId) => {
    setCanceOrderFlag(false)
    if (isExcuted) {
      cancelFuture(orderId)
    }
  }

  const excuteFutureConfirm = (orderId, purachasePrice) => {
    setExcuteOrderPurachsePrice(purachasePrice)
    setExcuteOrderId(orderId)
    setExcuteOrderFlag(true)
  }

  const cancelFutureConfirm = (orderId, purachasePrice) => {
    setExcuteOrderPurachsePrice(purachasePrice)
    setExcuteOrderId(orderId)
    setCanceOrderFlag(true)
  }

  const maxToken = () => {
    const returnValue = calculateMaxToken()
    setAmount(returnValue.bnb)
    setTokenGet(returnValue.token)
  }

  return (
    <>
      <TransactionConfirmationModal
        isOpen={showConfirm}
        startMark={startMark}
        onDismiss={handleDismissConfirmation}
        attemptingTxn={txHash === '' ? true : false}
        hash={txHash}
        content={() => <></>}
        pendingText="Waiting for Confirmation"
      />
      <FutureExcuteConfimation
        orderId={excuteOrderId}
        isOpen={excuteOrderFlag}
        tokenSymbol={data.tokenInfo.symbol}
        currentPrice={data.offeringTokenPrice}
        callBack={excuteFutureCB}
        purchasePrice={excuteOrderPurachsePrice}
      />
      <FutureCancelConfimation
        orderId={excuteOrderId}
        isOpen={cancelOrderFlag}
        currentPrice={data.poolInfo.apy}
        callBack={cancelFutureCB}
        purchasePrice={excuteOrderPurachsePrice}
      />
      <SidebarMBX style={{ width: `${isFutureBarOpen ? '100%' : '0%'}` }}>
        <SidebarContainer>
          <SBTitle>Secure Future</SBTitle>
          <SBText01>
            {/* Rather than staking funds directly on Compound, yearn.finance, Venus and similar decentralized staking protocols, enjoy from zero miss opportunity by stake indirectly with PDO Secure Future while retaining an early spot in a winning token.  */}
            Rather than staking funds directly on Compound, Yearn.finance, Venus and similar decentralized staking protocols, enjoy from zero miss opportunity by stake indirectly with PDO Secure Future while retaining an early spot in a winning token.
          </SBText01>
          <SBText01>
            This is how it works
            <SBOrderList>
              <li>Invest funds in a PDO with Secure Future, retaining early adopter APY rewards of the alt token.</li>
              <li>Funds are sent to third party staking protocols like Curve and Venus.</li>
              <li>During this time, funds are earning interest while observing the alt token.</li>
              <li>Final investment in the alt token can take place at any time before the PDO end date.</li>
              <li>If investment in the alt token is selected, a smart contract finalizes the investment, moving the funds from the {' '}
              </li>
              <li>Secure Future through the traditional PDO mechanism, sending funds as directed by the project launching the PDO.{' '}
              </li>
              <li>Users receive all the early adopter APY rewards plus additional alt tokens based on the current price in the market. {' '}
              </li>
              <li>All the funds generated from staking benefit the community, buying the alt token from the pool, then burning.<br />
If the investment is canceled before the due date, early adopter APY in the alt token is forfeited. Instead, users receive a 100% refund of principal funds plus 100% of the generated staking funds.
              </li>
            </SBOrderList>
          </SBText01>
          <SSswapMBX>
            <SSswpSBX01>
              <div className="ssSubBX01">
                <div className="imgBX">
                  <img onError={addDefaultSrc} src={tokenLogo} alt="" />
                </div>
                {data.tokenInfo.symbol.toUpperCase()} <span>{capitalizeFirstLetter(data.tokenInfo.name)}</span>
              </div>
              <div className="ssSubBX02">
                <span> {data.pdoType === 'FIXSALE' ? 'Fix Sale' : 'Dutch Auction'}</span>
                <span className="smtxt01">
                  {data.poolInfo.apy} % APY / {data.poolInfo.period} days
                </span>
                {data.isDumperSheild ? (
                  <span className="smtxt02">
                    <i className="fas fa-shield-alt"></i> Dumper Shield
                    <ToolTip tip={'Coming Soon'} id={'m1'} />
                  </span>
                ) : (
                  <span className="smtxt02" style={{ opacity: 0 }}>
                    {' '}
                    <i className="fas fa-shield-alt"></i> Dumper Shield
                    <ToolTip tip={'Coming Soon'} id={'m1'} />{' '}
                  </span>
                )}
              </div>
            </SSswpSBX01>
            <SSswpSBX02>
              {data.endTime > new Date().getTime() / 1000 && (
                <span style={{ marginBottom: '7px' }}>Current PDO End in</span>
              )}
              <SSTimerBx>
                <IFOCounter counterTime={data.endTime}></IFOCounter>
              </SSTimerBx>
              1 BNB = ${price}, 1 {data.tokenInfo.symbol} = ${data.offeringTokenPrice}
            </SSswpSBX02>
          </SSswapMBX>

          <IFOGraph>
            <i>Total investment {convertToPercantage(Number(data.percentAgeRaised))}%</i>
            <SpanIFOGraph style={{ width: data.percentAgeRaised + '%' }}></SpanIFOGraph>
          </IFOGraph>
          <SSswapTitle01>
            Total pending funds ready to deploy{' '}
            <ToolTip tip={'Pending investments one step away from executing their SMART reward.'} id={'m1'} />{' '}
            <span>$ {convertToCurrency(data.remaningPushAmount)}</span>
          </SSswapTitle01>

          <SSTableBX>
            <table width="100%" cellSpacing="0" cellPadding="10">
              <tbody>
                <tr>
                  <th scope="col">
                    Days to <br />
                    due date
                  </th>
                  <th scope="col">
                    Your <br />
                    investment{' '}
                  </th>

                  <th scope="col">
                    Secured reward <br />
                    from APY
                    <ToolTip tip={'Your current token reward if you execute your investment'} id={'m1'} />
                  </th>

                  <th scope="col">
                    Secured reward <br />
                    value
                    <ToolTip tip={'Your current APY value if you cancel your investment'} id={'m1'} />
                  </th>

                  <th scope="col">
                    Staking reward 
                    <ToolTip tip={'Your current APY value if you cancel your investment'} id={'m1'} />
                  </th>

                  <th scope="col" className="center">
                    Secured APY
                  </th>
                  <th scope="col">
                    Allocation to <br />
                    receive
                    <ToolTip tip={'Remaining number of tokens available to purchase'} id={'m1'} />
                  </th>

                  <th scope="col">
                    Staking reward <br />
                    receive
                    <ToolTip tip={'Remaining number of tokens available to purchase'} id={'m1'} />
                  </th>
                  <th scope="col">Take an action</th>
                </tr>
                {new Date().getTime() / 1000 > data.endTime || data.totalSupply < 0.00001 ? (
                  <tr>
                    <td colSpan={9} style={{ textAlign: 'center' }}>
                      Pdo Is over
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td>
                      {new Date().getTime() / 1000 > data.endTime
                        ? 0
                        : Math.ceil((data.endTime - new Date().getTime() / 1000) / 86400)}
                    </td>
                    <td style={{ position: 'relative' }}>
                      <input
                        type="text"
                        value={amount}
                        placeholder="Enter BNB"
                        onChange={event => {
                          enforcer(event.target.value.replace(/,/g, '.'))
                        }}
                        inputMode="decimal"
                        autoComplete="off"
                        autoCorrect="off"
                        pattern="^[0-9]*[.,]?[0-9]*$"
                        className="nInputbx"
                      />

                      <IDOMaxBtn onClick={maxToken}> Max </IDOMaxBtn>
                    </td>
                    <td> {convertToToken((tokenGet * data.poolInfo.period * data.poolInfo.apy) / (100 * 365))}</td>
                    <td>
                      {' '}
                      {convertToCurrency(
                        ((tokenGet * data.poolInfo.period * data.poolInfo.apy) / (100 * 365)) * data.offeringTokenPrice
                      )}
                    </td>
                    <td>
                      {' '}
                      {convertToCurrency(
                        ((tokenGet * data.poolInfo.period * data.poolInfo.apy) / (100 * 365)) * data.offeringTokenPrice
                      )}
                    </td>
                    <td className="center">
                      {' '}
                      <SStBTNBar className="fullWidth">
                        <button onClick={handleInvestment} className="sm">
                          Secured Reward
                        </button>
                      </SStBTNBar>
                    </td>
                    <td>{convertToToken(tokenGet)}</td>
                    <td> {convertToToken((tokenGet * data.poolInfo.period * data.poolInfo.apy) / (100 * 365))}</td>
                    <td></td>
                  </tr>
                )}
                {orders.map(order => {
                  return order.isFuture ? (
                    <tr>
                      <td>
                        {Number(data.endTime) > new Date().getTime() / 1000
                          ? Math.ceil((data.endTime - new Date().getTime() / 1000) / 86400)
                          : 0}
                      </td>
                      <td>$ {convertToCurrency(order.usdAmount)}</td>
                      <td>{convertToTokenFourDecimal(order.tokenReward)}</td>
                      <td>$ {convertToCurrency(order.tokenReward * data.offeringTokenPrice)}</td>
                      <td>$ {convertToCurrency(order.tokenReward * data.offeringTokenPrice)}</td>

                      {order.futureStatus === 'PENDING' ? (
                        new Date().getTime() / 1000 > data.endTime ? (
                          <td className="center gryTxt">Time over</td>
                        ) : (
                          <td className="center">Pending</td>
                        )
                      ) : order.futureStatus === 'CANCELLED' ? (
                        <td className="center">
                          <SStBTNBar className="fullWidth">
                            <button className="sm redCV3">Cancelled</button>
                          </SStBTNBar>
                        </td>
                      ) : (
                        <td className="center">
                          <SStBTNBar className="fullWidth">
                            {' '}
                            <button className="sm v3"> Executed</button>
                          </SStBTNBar>
                        </td>
                      )}

                      <td>{convertToToken(order.tokenAmount)}</td>
                      <td>{convertToToken(order.tokenReward)}</td>
                      <td>
                        {order.futureStatus === 'PENDING' ? (
                          <SStBTNBar>
                            <button
                              className="sm"
                              onClick={() => excuteFutureConfirm(order.orderAddress, order.createdPrice)}
                            >
                              Claim
                            </button>
                            {new Date().getTime() / 1000 < data.endTime && (
                              <button
                                className="v2"
                                onClick={() => cancelFutureConfirm(order.orderAddress, order.createdPrice)}
                              >
                                {' '}
                                <i className="far fa-times-circle"></i>
                              </button>
                            )}
                          </SStBTNBar>
                        ) : order.futureStatus === 'CANCELLED' ? (
                          <SStBTNBar>
                            <button className="sm redCV3">
                              <i className="fas fa-times"></i> Refunded
                            </button>
                          </SStBTNBar>
                        ) : (
                          <SStBTNBar>
                            <button className="sm v3">
                              <i className="fas fa-check"></i> Claimed
                            </button>
                          </SStBTNBar>
                        )}
                      </td>
                    </tr>
                  ) : (
                    <></>
                  )
                })}
              </tbody>
            </table>
          </SSTableBX>
        </SidebarContainer>
        <ClosBTN onClick={() => setIsFutureBarOpen(false)} ><i className="fas fa-times"></i></ClosBTN>
      </SidebarMBX>
    </>
  )
}

const IDOMaxBtn = styled.a`
  display: flex;
  font-size: 10px;
  color: #00f02b;
  text-align: left;
  position: absolute;
  right: 25px;
  top: 18px;
`

const IFOCounter02 = styled.div`
  font-weight: 700;
  margin: 10px 0;
  font-size: 18px;
  color: ${({ theme }) => theme.ifoText1};
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
`

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
`
const ClosBTN = styled.button`
  display: block;
  width: 30px;
  height: 30px;
  padding: 0;
  /* background: url(${CloseIMG}) 50% 50% no-repeat; */
  background: none;
  position: absolute;
  right: 20px;
  top: 20px;
  border: none;
  outline: none;
  transition: 400ms ease-in-out;
  font-size: 30px; color: #fff;
  line-height: 30px;
  :hover {
    transform: rotate(180deg);
  }
`
const SidebarMBX = styled(FlexDiv)`
  width: 100%;
  position: fixed;
  background-color: #16191e;
  z-index: 99;
  right: 0;
  top: 0;
  bottom: 0;
  max-width: 1230px;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-y: auto;
`
const SidebarContainer = styled(FlexDiv)`
  padding: 80px 60px 60px 60px;
  border-left: 2px solid #000;
  ${Media.md} {
    padding: 80px 30px 30px 30px;
  }
`
const SBTitle = styled(FlexDiv)`
  font: 700 36px/40px 'Kanit', arial;
  color: #fff;
`
const SBText01 = styled.div`
  font-size: 16px;
  width: 100%;
  color: #8e9195;
  line-height: 30px;
  text-align: left;
  margin: 20px 0 45px 0;
  & + & {
    margin-top: -25px;
  }
`
const SBOrderList = styled.ol`
  padding: 0 0 0 30px;
  margin: 0;
  li {
    margin: 0;
  }
`
const SSswapMBX = styled(FlexDiv)`
  margin: 0 auto 25px auto;
`
const SSswpSBX01 = styled(FlexDiv)`
  width: 45%;
  position: relative;
  text-align: left;
  padding: 14px 0 0 0;
  align-items: center;
  justify-content: flex-start;

  ${Media.sm} {
    padding: 30px 0;
    width: 100%;
    border: 0;
  }

  & .imgBX {
    border-radius: 50px;
    width: 74px;
    height: 74px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 10px;
    top: 0;
    img {
      max-width: 100%;
      height: auto;
      border-radius: 50px;
    }
  }

  .ssSubBX01 {
    width: 50%;
    display: flex;
    font-size: 24px;
    font-weight: 700;
    padding-left: 96px;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-right: 1px solid #393d46;
    padding-right: 8px;
    min-height: 70px;
    span {
      font-size: 14px;
      font-weight: 400;
    }
  }

  .ssSubBX02 {
    width: 40%;
    display: flex;
    color: #00f02b;
    font-size: 14px;
    font-weight: 700;
    padding-left: 30px;
    position: relative;
    flex-direction: column;
    line-height: 20px;
    justify-content: center;
    align-items: flex-start;
    border-right: 1px solid #393d46;
    padding-right: 8px;

    ${Media.sm} {
      border: 0;
    }

    .smtxt01 {
      color: #6c7076;
      font-size: 11px;
      font-weight: 400;
    }
    .smtxt02 {
      font-size: 11px;
      font-weight: 400;
    }
  }
`

const SSswapTitle01 = styled(FlexDiv)`
  width: 100%;
  font: 700 24px/34px 'Kanit', arial;
  color: #00f02b;
  margin-bottom: 28px;
  span {
    margin-left: 20px;
  }
`

const SSTimerBx = styled(FlexDiv)`
  flex-direction: row;
  margin-bottom: 6px;
  color: #fff;
  ${Media.sm} {
    justify-content: flex-start;
  }

  // &.kDwjzg {font-size:22px !important; font-weight:700;}
`
const SpanTime = styled.span`
  font-size: 22px;
  font-weight: 700;
  border: 1px solid #5a5e67;
  display: inline-block;
  border-radius: 4px;
  color: #fff;
  padding: 6px 8px;
  margin: 0 2px;

  ${Media.md} {
    font-size: 18px;
    font-weight: 600;
    padding: 4px 4px;
    margin: 0 2px;
  }
`
const SSswpSBX02 = styled(FlexDiv)`
  width: 40%;
  font-size: 11px;
  color: #8e9195;
  margin-top: 16px;

  ${Media.sm} {
    width: 100%;
    justify-content: flex-start;
  }
`
const SSTableBX = styled(FlexDiv)`
  ${Media.md} {
    width: 100%;
    overflow-y: auto;
    align-items: flex-start;
    justify-content: flex-start;
  }
  & table {
    width: 100%;
    font-size: 11px;
    color: #fff;
    tr {
      border-bottom: 1px solid #545861;
      :hover {
        background-color: #07080a;
        td {
          border-bottom: 1px solid #00f02b;
        }
      }
    }
    tr th {
      color: #9a9a9a;
      font-weight: 400;
      text-align: left;
      border-bottom: 2px solid #545861;
      border-top: 2px solid #545861;
      border-right: 1px solid #545861;
      line-height: 20px;
      :nth-last-child(1) {
        border-right: 0px;
        text-align: center;
      }
      i {
        top: -5px;
        transform: translateX(7px);
      }
    }
    tr td {
      border-right: 1px solid #545861;
      padding: 5px 8px;
      :nth-last-child(1) {
        border-right: 0px;
      }
    }
    tr td.center,
    tr th.center {
      text-align: center;
    }
    tr td.gryTxt {
      color: #53575d;
    }
    tr td:nth-last-child(4),
    tr th:nth-last-child(4) {
      border-right: 4px solid #545861;
    }
    .nInputbx {
      width: 180px;
      height: 37px;
      border: 2px solid #5a5e67;
      outline: none;
      background-color: transparent;
      color: #fff;
      font-size: 11px;
      padding: 0 4px;
    }
    ${Media.md} {
      width: 800px;
    }
  }
`
const SStBTNBar = styled(FlexDiv)`
  justify-content: space-between;
  min-width: 120px;
  flex-wrap: nowrap;
  &.autoWidth {
    min-width: 60px;
    button {
      padding: 6px 6px !important;
      min-width: 60px !important;
    }
  }
  &.fullWidth {
    width: 130px;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    button {
      padding: 9px 8px !important;
      min-width: 60px !important;
    }
  }
  &.leftAlign {
    justify-content: flex-start;
  }
  button {
    display: inline-block;
    font-size: 12px;
    background-color: transparent;
    outline: none;
    padding: 6px 14px;
    border: 2px solid #00f02b;
    box-shadow: 0 0 8px #00f02b;
    border-radius: 5px;
    color: #00f02b;
    width: 100%;
    text-align: center;
    margin: 0 4px;
    :hover {
      background-color: #00f02b;
      color: #fff;
    }
    &.sm {
      width: auto;
      min-width: 89px;
      :hover {
        color: #00f02b;
        color: #fff;
      }
    }
    &.nobg {
      border: 0px;
      color: #fff;
      min-width: 89px;
      :hover {
        color: #00f02b;
        background: transparent;
      }
    }
    &.v2 {
      background: transparent;
      padding: 6px 0;
      width: auto;
      font-size: 20px;
      border: 0px;
      color: #53575d;
      :hover {
        color: #c12a2c;
      }
    }
    &.v3 {
      background: transparent;
      padding: 6px 0;
      width: auto;
      font-size: 12px;
      border: 0px;
      color: #00f02b;
      :hover {
        color: #10cd32;
      }
    }
    &.redC {
      border-color: #c32b2d;
      color: #c32b2d;
      :hover {
        background-color: #c12a2c;
        color: #fff;
      }
    }
    &.redCV2 {
      color: #c32b2d;
      border: none;
      :hover {
        color: #fff;
        background-color: #c12a2c;
      }
    }
    &.redCV3 {
      background: transparent;
      padding: 6px 0;
      width: auto;
      font-size: 12px;
      border: 0px;
      color: #c32b2d;
      :hover {
        color: #c12a2c;
      }
    }
  }
`

const IFOGraph = styled.div`
  width: 100%;
  margin: 30px 0 20px 0;
  height: 25px;
  background-color: #393d46;
  border-radius: 30px;
  position: relative;
  & i {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    font-style: normal;
    color: #0f0f0f;
    z-index: 2;
    padding-top: 7px;
    font-size: 11px;
  }
`
const SpanIFOGraph = styled.span`
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: #00f02b;
    border-radius: 30px;
    box-shadow: 0 0 10px #00f02b;
`
