/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import { useAlert } from 'react-alert'

import styled from 'styled-components'
import { isAddress, getEtherscanLink } from '../../utils'
import { useTokenName } from '../../data/TokenName'
import { useRouterFactory } from '../../data/Router'
import TextBox from '../TextBox/index'
import TextBoxWithDecimal from '../TextBoxWithDecimal/index'
import AddressTextBox from '../AddressTextBox/index'
import ToolTip from '../ToolTip'
import Media from '../../theme/media-breackpoint'
import useOutsideClick from '../../hooks/useOutsideClick'

import { IFOLaunchTabProps } from '../../pages/IFOLaunch'
import DatePicker from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'

import { useCurrency } from '../../hooks/Tokens'
import { ApprovalState, useApproveCallback } from '../../hooks/useApproveCallback'
import { Currency, CurrencyAmount, JSBI, Token, TokenAmount } from '@bscswap/sdk'
import { parseUnits } from '@ethersproject/units'
import { FACTORY_PDO_ADDRESSES } from '../../constants/pdo'
import { useActiveWeb3React } from '../../hooks'

import { _nameprepTableB2 } from '@ethersproject/strings/lib/idna'
import { useDumperSheildContract, useFactoryContract, useTokenContract } from '../../hooks/useContract'

import { API_BASEURL } from '../../constants'

import questionLogo from '../../assets/svg/questionLogo.svg'
import { convertToCurrency, convertToToken } from '../../utils/numberConveter'
import web3Js from 'web3'
import { event } from 'react-ga'

const api = axios.create({
  baseURL: API_BASEURL
})

function tryParseAmount(value?: string, currency?: Currency): CurrencyAmount | undefined {
  if (!value || !currency) {
    return
  }
  try {
    const typedValueParsed = parseUnits(value, currency.decimals).toString()
    console.log('typedValueParsed', typedValueParsed)
    if (typedValueParsed !== '0') {
      return currency instanceof Token
        ? new TokenAmount(currency, JSBI.BigInt(typedValueParsed))
        : CurrencyAmount.ether(JSBI.BigInt(typedValueParsed))
    }
  } catch (error) {
    // should fail if the user specifies too many decimal places of precision (or maybe exceed max uint?)
    console.debug(`Failed to parse input amount: "${value}"`, error)
  }
  // necessary for all paths to return a value
  return
}
interface SwapList {
  pancakeswapPairList: Array<any>
  uniswapPairList: Array<any>
  sushiswapPairList: Array<any>
}

const uniswapTokenAddress = '0x1f9840a85d5af5bf1d1762f925bdaddc4201f984'

export default function IFOLaunchTab1({ Value, setValue, moveToTab, factoryValues }: IFOLaunchTabProps) {
  const swapMenuRef = useRef()
  const poolMenuRef = useRef()

  const [ammName, setAmmName] = useState('')
  const [ammLogo, setAmmLogo] = useState('')
  const [showSwapMenu, setShowSwapMenu] = useState(false)
  const [showPoolMenu, setShowPoolMenu] = useState(false)
  const [ammServices, setAmmServices] = useState([])
  const [selectedAmmService, setSelectedAmmService] = useState<number>(0)
  const [ammPairList, setAmmPairList] = useState([])
  const [selectedAmmPair, setSelectedAmmPair] = useState<number>(0)

  const [tokenAddress, setTokenaddress] = useState('')
  const [pairedAddress, setPairedaddress] = useState('')
  const [routerAddress, setRouteraddress] = useState('')
  const [offeringTokenPrice, setOfferingTokenPrice] = useState<number>(0)

  const [poolAddress, setPoolAddress] = useState('')
  const factoryAdress = useRouterFactory(routerAddress)
  const factoryContract = useFactoryContract(factoryAdress)
  const dumperSheild = useDumperSheildContract()

  const [apy, setApy] = useState<number>(0)
  const [period, setPeriod] = useState<number>(0)
  const [tokenSupply, setTokenSupply] = useState<number>(0)
  const [distrbuteSupply, setDistrbuteSupply] = useState<number>(0)
  const [maxCap, setMaxCap] = useState<number>(0)

  const { account, chainId } = useActiveWeb3React()
  const alert = useAlert()
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [dsDate, setDsDate] = useState(new Date())
  const [isDsDate, setIsDsDate] = useState(false)
  const offeringToken = useCurrency(Value.poolInfo.offeringToken)
  const ot = tryParseAmount(Value.totalSupply.toString(), offeringToken)
  const tokenContract = useTokenContract(tokenAddress)
  const [approvalFactory, approveFactoryCallback] = useApproveCallback(ot, FACTORY_PDO_ADDRESSES[chainId])
  const [lastApproval, setLastApproval] = useState<ApprovalState>(ApprovalState.UNKNOWN)

  useEffect(() => {
    if (tokenAddress === '' || selectedAmmService === -1) {
      setAmmPairList([])
      return
    }
    api
      .get(
        `/getTokenPairsForFactory/${ammServices[selectedAmmService].chainId}/${ammServices[selectedAmmService].factoryAddress}/${tokenAddress}`
      )
      .then(response => {
        setAmmPairList(response.data.data)
      })
    Value.poolInfo.dexAddress = ammServices[selectedAmmService].routerAddress
    setRouteraddress(ammServices[selectedAmmService].routerAddress)
    setValue(values => ({
      ...values
    }))
  }, [selectedAmmService, tokenAddress, ammServices])

  useEffect(() => {
    if (ammPairList.length === 0) {
      return
    }
    if (ammPairList[selectedAmmPair].token0.address !== tokenAddress.toLowerCase()) {
      Value.poolInfo.pairedToken = ammPairList[selectedAmmPair].token0.address
      setPairedaddress(ammPairList[selectedAmmPair].token0.address)
    }
    if (ammPairList[selectedAmmPair].token1.address !== tokenAddress.toLowerCase()) {
      Value.poolInfo.pairedToken = ammPairList[selectedAmmPair].token1.address
      setPairedaddress(ammPairList[selectedAmmPair].token1.address)
    }
    setValue(values => ({
      ...values
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ammPairList, selectedAmmPair])

  useEffect(() => {
    const checkDsDate = async () => {
      let date = await dumperSheild.dsUnlockDate(tokenAddress)
      date = web3Js.utils.hexToNumberString(date._hex)
      if (Number(date) > 0) {
        setDsDate(new Date(Number(date) * 1000))
        setIsDsDate(true)
      } else {
        setDsDate(new Date())
        setIsDsDate(false)
      }
    }
    if (tokenAddress !== '' && pairedAddress !== '' && routerAddress !== '') {
      api.get(`/getTokenPrice/${routerAddress}/${pairedAddress}/${tokenAddress}`).then(response => {
        setOfferingTokenPrice(response.data.data)
      })
    }
    if (tokenAddress !== '') {
      checkDsDate()
    }
  }, [tokenAddress, pairedAddress, routerAddress])

  useEffect(() => {
    let dSupply = tokenSupply
    if (period > 0 && apy > 0) {
      const rate = (apy * period) / (365 * 100) + 1
      dSupply = tokenSupply / rate
      setDistrbuteSupply(dSupply)
    }

    const cap = dSupply * 0.9 * offeringTokenPrice
    setMaxCap(cap)
  }, [apy, period, tokenSupply])

  useOutsideClick(swapMenuRef, () => {
    setShowSwapMenu(false)
  })
  useOutsideClick(poolMenuRef, () => {
    setShowPoolMenu(false)
  })

  useEffect(() => {
    api
      .post(`/getDex`, {
        filter: {
          chainId: chainId
        }
      })
      .then(response => {
        setAmmServices(response.data.data)
      })
  }, [])

  useEffect(() => {
    const move = async () => {
      const tokenBalance = await tokenContract.balanceOf(account)
      const offeringTokenBalance = getTokenAmountInWei(tokenSupply, offeringToken?.decimals)

      if (parseInt(offeringTokenBalance?.toString()) > parseInt(tokenBalance.toString())) {
        alert.show('You dont have enough Token balance')
        return
      }
      moveToTab()
    }

    if (lastApproval === ApprovalState.PENDING) {
      if (Value.pdoType === 1) {
        let supply
        if (Value.poolInfo.period > 0) {
          supply = distrbuteSupply
        } else {
          supply = tokenSupply
        }
        const cap = supply * 0.9 * offeringTokenPrice

        if (Value.softCap >= cap) {
          alert.show('soft cap is larger than provided supply amount')
          return
        }
        move()
      }
    }
    setLastApproval(approvalFactory)
  }, [approvalFactory])

  useEffect(() => {
    // const poolAddress = useFactoryPool(factory,tokenAddress,pairedAddress);
    const gePair = async () => {
      const pairAddress = await factoryContract.getPair(tokenAddress, pairedAddress)
      setPoolAddress(pairAddress)
    }
    if (factoryContract === null) {
      return
    }
    if (isAddress(tokenAddress) && isAddress(pairedAddress)) {
      gePair()
    } else {
      setPoolAddress('')
    }
  }, [factoryContract, tokenAddress, pairedAddress])

  const [endDatePlusOneDay, setEndDatePlusOneDay] = useState(new Date())
  useEffect(() => {
    const temp = new Date(startDate)
    temp.setDate(temp.getDate() + 1)
    setEndDatePlusOneDay(temp)
    setEndDate(temp)
  }, [startDate])

  const name = useTokenName(tokenAddress)
  const pairTokenName = useTokenName(pairedAddress)
  const handleOfferingTokenChange = async event => {
    if (isAddress(event) || event === '') {
      setTokenaddress(event)
    }
    Value.poolInfo.offeringToken = event
    setValue(values => ({
      ...values
    }))
  }

  const handleRouterAddressChange = event => {
    Value.poolInfo.dexAddress = event
    setValue(values => ({
      ...values
    }))
    setRouteraddress(event)
  }

  const handleStartTimeChange = date => {
    const end = new Date(date)
    // add a day
    end.setDate(end.getDate() + 1)
    setValue(values => ({
      ...values,
      startTime: date,
      endTime: end
    }))
  }

  const handleDsTimeChange = date => {
    setValue(values => ({
      ...values,
      dumperShedilDay: date
    }))
  }

  const handleEndTimeChange = date => {
    setValue(values => ({
      ...values,
      endTime: date
    }))
  }

  const handleSupplyToDistributeChange = event => {
    setTokenSupply(event)
    setValue(values => ({
      ...values,
      totalSupply: event
    }))
  }

  const handleSoftCapChange = event => {
    setValue(values => ({
      ...values,
      softCap: event
    }))
  }
  const handlePeriodChange = event => {
    Value.poolInfo.period = Number(event)
    setValue(values => ({
      ...values
    }))
    setPeriod(Number(event))
  }
  const handleApyChange = event => {
    Value.poolInfo.apy = event
    setValue(values => ({
      ...values
    }))
    setApy(event)
  }

  const handleAmm = event => {
    setAmmName(event)
  }

  const handleAmmLogo = event => {
    setAmmLogo(event)
  }

  // const handleProjectAddressChange = (event) => {
  //     setValue((values) => ({
  //         ...values,
  //         projectAddress: event
  //     }));
  // }
  const handleMinimumInvestmentChange = event => {
    Value.investInfo.minInvest = event
    if (Value.investInfo.maxInvest === 0 || Value.investInfo.minInvest > Value.investInfo.maxInvest) {
      Value.investInfo.maxInvest = event
    }
    setValue(values => ({
      ...values
    }))
  }
  const handleMaximumInvestmentChange = event => {
    Value.investInfo.maxInvest = event
    setValue(values => ({
      ...values
    }))
  }
  const handleIdoTypeChange = event => {
    // console.log(event.target.value);
    event.persist()
    setValue(values => ({
      ...values,
      pdoType: Number(event.target.value)
    }))
  }

  const handleLimitChange = event => {
    // console.log(event.target.value);
    event.persist()
    Value.investInfo.limitType = Number(event.target.value)
    setValue(values => ({
      ...values
    }))
  }

  const handleDumperChange = event => {
    // console.log(event.target.value);
    event.persist()
    setValue(values => ({
      ...values,
      isDumperSheild: Number(event.target.value) === 1 ? true : false
    }))
  }

  const handleDumperDaysChange = event => {
    // console.log(event.target.value);
    event.persist()
    setValue(values => ({
      ...values,
      isDumperSheild: Number(event.target.value) === 1 ? true : false
    }))
  }

  const handleOnPauseLiquidity = event => {
    event.persist()
    Value.investInfo.liquidityProtection = Number(event.target.value)
    setValue(values => ({
      ...values
    }))
  }
  const handleKycRequiredChange = event => {
    // console.log(event.target.value);
    event.persist()
    setValue(values => ({
      ...values,
      kyc: event.target.value === '1' ? true : false
    }))
  }
  const handleFairPdoChange = event => {
    // console.log(event.target.value);
    event.persist()

    setValue(values => ({
      ...values,
      fairPdo: event.target.value === '1' ? true : false
    }))
  }

  const handleKycChange = event => {
    // console.log(event.target.value);
    event.persist()
    Value.investInfo.isKyc = event.target.value === '1' ? true : false
    setValue(values => ({
      ...values
    }))
  }

  const handlePairedAddressChange = event => {
    setPairedaddress(event)
    Value.poolInfo.pairedToken = event
    setValue(values => ({
      ...values
    }))
  }

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  const showErrorMessage = () => {
    alert.show('Please enter valid token address')
  }

  const addQuestionLogo = ev => {
    ev.target.src = questionLogo
  }

  const getTokenAmountInWei = (amount: number, power: number | undefined) => {
    if (power === undefined) {
      return 0
    }
    return parseUnits(amount.toString(), power).toString()
  }

  const checkAndValidate = async () => {
    const tokenBalance = await tokenContract.balanceOf(account)
    const offeringTokenBalance = getTokenAmountInWei(tokenSupply, offeringToken?.decimals)

    if (parseInt(offeringTokenBalance?.toString()) > parseInt(tokenBalance.toString())) {
      alert.show('You dont have enough Token balance')
      return
    }

    if (poolAddress === '' || poolAddress === '0x0000000000000000000000000000000000000000') {
      alert.show('Token pair is not selected')
      return
    }
    if (Value.pdoType === 1) {
      let supply
      if (Value.poolInfo.period > 0) {
        supply = distrbuteSupply
      } else {
        supply = tokenSupply
      }
      const cap = supply * 0.9 * offeringTokenPrice

      if (Value.softCap > cap) {
        alert.show('soft cap is larger than provided supply amount')
        return
      }
    }
    if (selectedAmmService === -1) {
      if (ammName === '') {
        alert.show('please enter Amm Name')
        return
      }
      if (ammLogo === '') {
        alert.show('please enter Amm Logo Url')
        return
      }
      api
        .post(`/addAmm`, {
          chainId: chainId,
          routerAddress: routerAddress,
          ammName: ammName,
          ammLogo:ammLogo
        })
        .then(response => {
          setAmmServices(response.data.data)
        })
    }

    moveToTab()
  }

  const padTo2Digits = num => {
    return num.toString().padStart(2, '0')
  }

  const formatDate = date => {
    return [padTo2Digits(date.getDate()), padTo2Digits(date.getMonth() + 1), date.getFullYear()].join('/')
  }

  return (
    <JwbBonusFormMbox>
      <JwbBonusFMbox02>
        <JwbBonusFSbox011>
          <JwbbfBX01>Choose PDO type</JwbbfBX01>
          <JwbbfBX02>
            <RadioWrap03 className="tabFix01">
              <div className="md-radio">
                <Input
                  type="radio"
                  id="auction"
                  name="idoType"
                  value="0"
                  checked={Value.pdoType === 0}
                  onChange={handleIdoTypeChange}
                />
                <Label htmlFor="auction">
                  Fixed sale
                  <ToolTip tip={'Set price, traditional offering'} id={'m3'} />
                </Label>
              </div>
              <div className="md-radio">
                <Input
                  type="radio"
                  id="setPrice"
                  name="idoType"
                  value="1"
                  checked={Value.pdoType === 1}
                  onChange={handleIdoTypeChange}
                />
                <Label htmlFor="setPrice" style={{ paddingRight: '0' }}>
                  Dutch auction
                  <ToolTip tip={'Set a soft cap and all the market to set to the price'} id={'m2'} />
                </Label>
              </div>
            </RadioWrap03>
          </JwbbfBX02>
        </JwbBonusFSbox011>
        <JwbBonusFSbox02>
          <JwbbfBX01>Place your token contract</JwbbfBX01>
          <JwbbfBX02>
            <Jwbinput01 className="idoDollrFix02" data-name={name} data-kaam="mak">
              <JwbDropdown>
                {' '}
                {name !== undefined && <i className="idoDollrSign02">{name}</i>}
                {/* <TextBox placeholder='Enter Soft Cap'></TextBox> */}
                <AddressTextBox
                  value={Value.poolInfo.offeringToken}
                  onUserInput={handleOfferingTokenChange}
                  placeholder="Enter Address"
                ></AddressTextBox>
                <span className="v2">[TOKEN]</span>
                {/* missing drop down */}
              </JwbDropdown>
            </Jwbinput01>
          </JwbbfBX02>
        </JwbBonusFSbox02>
        {/* <JwbBonusFSbox02>
                    <JwbbfBX011>
                        Place your token contract
                    </JwbbfBX011>
                    <JwbbfBX02 className='inputFix01'>
                        <JwbInput01WithICO02FullWidth name={name}>
                        
                            <AddressTextBox value={Value.poolInfo.offeringToken} onUserInput={handleOfferingTokenChange} placeholder='Enter Address'></AddressTextBox>
                        </JwbInput01WithICO02FullWidth>
                    </JwbbfBX02>
                </JwbBonusFSbox02> */}
      </JwbBonusFMbox02>

      <JwbBonusFMbox>
        <JwbBonusFSbox01>
          <JwbbfBX011>{/* Select AMM Service */} Choose AMM</JwbbfBX011>
          <JwbbfBX02>
            {/* <Jwbinput01>
                            <AddressTextBox value={Value.poolInfo.dexAddress} onUserInput={handleRouterAddressChange} placeholder='Please enter Router address for AMM'></AddressTextBox>
                        </Jwbinput01> */}
            <DDNewMenu className={`${showSwapMenu ? 'open' : ''}`}>
              <DDBTN01 onClick={() => setShowSwapMenu(!showSwapMenu)} ref={swapMenuRef}>
                {ammServices.length > 0 && selectedAmmService >= 0 && (
                  <>
                    <DDTokenIMG>
                      <img src={ammServices[selectedAmmService].ammLogo} alt="" />
                    </DDTokenIMG>{' '}
                    {ammServices[selectedAmmService].ammName.toUpperCase()}
                    <i className="fas fa-sort-down"></i>
                  </>
                )}
                {selectedAmmService === -1 && (
                  <>
                    <DDTokenIMG>
                      <img src={questionLogo} alt="" />
                    </DDTokenIMG>{' '}
                    Other<i className="fas fa-sort-down"></i>
                  </>
                )}
              </DDBTN01>

              <DDdropDown>
                {ammServices.map((swap, id) => (
                  <DDBTN01 key={swap.id} onClick={() => setSelectedAmmService(id)}>
                    <DDTokenIMG>
                      <img src={swap.ammLogo} alt="" />
                    </DDTokenIMG>
                    {swap.ammName.toUpperCase()}
                  </DDBTN01>
                ))}
                <DDBTN01 onClick={() => setSelectedAmmService(-1)}>
                  <DDTokenIMG>
                    <img src={questionLogo} alt="" />
                  </DDTokenIMG>
                  Other
                </DDBTN01>
              </DDdropDown>
            </DDNewMenu>
          </JwbbfBX02>
        </JwbBonusFSbox01>

        {ammPairList.length > 0 && (
          <JwbBonusFSbox02>
            <JwbbfBX011>Choose liquidity pool</JwbbfBX011>
            <JwbbfBX02>
              <Jwbinput01>
                <DDNewMenu className={`${showPoolMenu ? 'open' : ''}`}>
                  <DDBTN01 onClick={() => setShowPoolMenu(!showPoolMenu)} ref={poolMenuRef}>
                    <DDTokenIMG02>
                      <span>
                        {' '}
                        <img src={ammPairList[selectedAmmPair].token0.logo} alt="" onError={addQuestionLogo} />
                      </span>
                      <span>
                        <img src={ammPairList[selectedAmmPair].token1.logo} alt="" onError={addQuestionLogo} />{' '}
                      </span>
                    </DDTokenIMG02>
                    {`${ammPairList[selectedAmmPair].token0.symbol.toUpperCase()} <> ${ammPairList[
                      selectedAmmPair
                    ].token1.symbol.toUpperCase()}`}
                    <i className="fas fa-sort-down"></i>
                  </DDBTN01>

                  <DDdropDown>
                    {ammPairList.map((pair, id) => (
                      // eslint-disable-next-line react/jsx-key
                      <DDBTN01 onClick={() => setSelectedAmmPair(id)}>
                        <DDTokenIMG02>
                          <span>
                            <img src={pair.token0.logo} alt="" onError={addQuestionLogo} />{' '}
                          </span>
                          <span>
                            <img src={pair.token1.logo} alt="" onError={addQuestionLogo} />{' '}
                          </span>
                        </DDTokenIMG02>
                        {`${pair.token0.symbol.toUpperCase()} <> ${pair.token1.symbol.toUpperCase()}`}
                      </DDBTN01>
                    ))}
                  </DDdropDown>
                </DDNewMenu>

                <SmallAlertText className="mt10">
                  <a
                    href={getEtherscanLink(chainId, ammPairList[selectedAmmPair].poolAddress, 'address')}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Verify that is the right pool contract <i className="fas fa-external-link-alt"></i>{' '}
                  </a>
                </SmallAlertText>
              </Jwbinput01>
            </JwbbfBX02>
          </JwbBonusFSbox02>
        )}
        {selectedAmmService === -1 && (
          <JwbBonusFSbox02>
            <JwbbfBX011>
              Enter Paired Token Address
              {/* <SmallAlertText>Zero fee for pool with [TOKEN]</SmallAlertText> */}
            </JwbbfBX011>

            <JwbbfBX02>
              <Jwbinput01 className="idoDollrFix02">
                <JwbDropdown>
                  {' '}
                  {pairTokenName !== undefined && <i className="idoDollrSign02">{pairTokenName}</i>}
                  {/* <TextBox placeholder='Enter Soft Cap'></TextBox> */}
                  <AddressTextBox
                    value={Value.poolInfo.pairedToken}
                    onUserInput={handlePairedAddressChange}
                    placeholder="Enter Pair Token Address"
                  ></AddressTextBox>
                  {isAddress(poolAddress) && (
                    <SmallAlertText className="mt10">
                      <a href={getEtherscanLink(chainId, poolAddress, 'address')} target="_blank" rel="noreferrer">
                        Verify that is the right pool contract <i className="fas fa-external-link-alt"></i>{' '}
                      </a>
                    </SmallAlertText>
                  )}
                </JwbDropdown>
              </Jwbinput01>
            </JwbbfBX02>
          </JwbBonusFSbox02>
        )}
      </JwbBonusFMbox>

      {selectedAmmService === -1 && (
        <>
          <JwbBonusFMbox>
            <JwbBonusFSbox01>
              <JwbbfBX011>
                Enter AMM Service router Address
                <a
                  href="https://docs.pancakeswap.finance/code/smart-contracts/pancakeswap-exchange/router-v2"
                  style={{ color: 'white' }}
                  target="_blank"
                  rel="noreferrer"
                >
                  <ToolTip tip={'see example for Pancakeswap router address  by clicking on here'} id={'m22'} />
                </a>
              </JwbbfBX011>
              <JwbbfBX02>
                <Jwbinput01>
                  <AddressTextBox
                    value={Value.poolInfo.dexAddress}
                    onUserInput={handleRouterAddressChange}
                    placeholder="Please enter Router address for AMM"
                  ></AddressTextBox>
                </Jwbinput01>
              </JwbbfBX02>
            </JwbBonusFSbox01>
            <JwbBonusFSbox02>
              <JwbbfBX011>
                Enter AMM Name
                {/* <SmallAlertText>Zero fee for pool with [TOKEN]</SmallAlertText> */}
              </JwbbfBX011>

              <JwbbfBX02>
                <Jwbinput01 className="idoDollrFix02">
                  <JwbDropdown>
                    <AddressTextBox
                      value={ammName}
                      onUserInput={handleAmm}
                      placeholder="Enter AMM Name"
                    ></AddressTextBox>
                  </JwbDropdown>
                </Jwbinput01>
              </JwbbfBX02>
            </JwbBonusFSbox02>
          </JwbBonusFMbox>
          <JwbBonusFMbox>
              <JwbBonusFSbox01>
              <JwbbfBX011>
                Enter AMM Logo URL
                {/* <SmallAlertText>Zero fee for pool with [TOKEN]</SmallAlertText> */}
              </JwbbfBX011>

              <JwbbfBX02>
                <Jwbinput01 className="idoDollrFix02">
                  <JwbDropdown>
                    <AddressTextBox
                      value={ammLogo}
                      onUserInput={handleAmmLogo}
                      placeholder="Enter AMM Logo URL"
                    ></AddressTextBox>
                  </JwbDropdown>
                </Jwbinput01>
              </JwbbfBX02>
            </JwbBonusFSbox01>
          </JwbBonusFMbox>
        </>
      )}

      <JwbBonusFMbox>
        <JwbBonusFSbox01>
          <JwbbfBX011>
            Start PDO Date <ToolTip tip={'Daily start time is 12:00pm UTC'} id={'m22'} />
          </JwbbfBX011>
          <JwbbfBX02>
            <Jwbinput01>
              <JwbDropdown>
                <FlyCalICON>
                  <i className="fas fa-calendar-alt"></i>
                </FlyCalICON>

                <DatePicker
                  selected={startDate}
                  minDate={Date.now()}
                  onChange={date => {
                    setStartDate(date)
                    handleStartTimeChange(date)
                  }}
                />
              </JwbDropdown>
            </Jwbinput01>
          </JwbbfBX02>
        </JwbBonusFSbox01>
        <JwbBonusFSbox02>
          <JwbbfBX01>
            End PDO Date <ToolTip tip={'Daily end time is 11:59am UTC'} id={'m21'} />
          </JwbbfBX01>
          <JwbbfBX02>
            <Jwbinput01>
              <JwbDropdown>
                <FlyCalICON>
                  <i className="fas fa-calendar-alt"></i>
                </FlyCalICON>
                <DatePicker
                  selected={endDate}
                  minDate={endDatePlusOneDay}
                  onChange={date => {
                    // console.log(date)
                    setEndDate(date)
                    handleEndTimeChange(date)
                  }}
                />
              </JwbDropdown>
            </Jwbinput01>
          </JwbbfBX02>
        </JwbBonusFSbox02>
      </JwbBonusFMbox>

      {/* <JwbBonusFMbox02>
            <JwbBonusFSbox011>
                <JwbbfBX01>
                    Staking required?
                </JwbbfBX01>
                <JwbbfBX02>
                    <RadioWrap>
                        <div className="md-radio">
                            <Input type="radio" id="stakingYes" name="staking" value="1" checked={value.isStaking === true} onChange={handleStakingRequiredChange} />
                            <Label htmlFor="stakingYes"> Yes
                        </Label>
                        </div>
                        <div className="md-radio">
                            <Input type="radio" id="stakingNo" name="staking" value="0" checked={value.isStaking === false} onChange={handleStakingRequiredChange} />
                            <Label htmlFor='stakingNo'>No</Label>
                        </div>
                    </RadioWrap>
                </JwbbfBX02>
            </JwbBonusFSbox011>
            <JwbBonusFSbox02>
                <JwbDropdown>
                      <TextBox placeholder='For sending $1,000 and above'></TextBox>  
                      
                </JwbDropdown>
            </JwbBonusFSbox02>
        </JwbBonusFMbox02> */}

      <JwbBonusFMbox>
        <JwbBonusFSbox01>
          <JwbbfBX011>
            Distribution supply <br />
            total{' '}
            <ToolTip
              tip={
                'The total amount includes allocation and the highest possible APY reward scenario. For example, if you 1,000,000 tokens with 100% APY. 500,000 tokens are used for investment allocation and the other 500,000 is the APY reward. Leftover tokens are returned at the conclusion of the PDO or burned through fair launch.'
              }
              id={'tipSupply'}
            />
          </JwbbfBX011>
          <JwbbfBX02>
            <Jwbinput01>
              <JwbDropdown>
                <TextBoxWithDecimal
                  value={Value.totalSupply}
                  onUserInput={handleSupplyToDistributeChange}
                  placeholder="Please enter supply to distribute"
                ></TextBoxWithDecimal>
                {/* missing drop down */}
              </JwbDropdown>
              {distrbuteSupply > 0 && (
                <SmallAlertText className="mt10">
                  Total supply splits {convertToToken(distrbuteSupply)} allocation /{' '}
                  {convertToToken(tokenSupply - distrbuteSupply)} APY
                </SmallAlertText>
              )}
            </Jwbinput01>
          </JwbbfBX02>
        </JwbBonusFSbox01>
        {/* <Collapse isOpened={value.ifoType === '2'? true: false} > */}
        {/* {value.idoType === '2' ? (
                ) : null}  */}
        {Value.pdoType === 1 && (
          <JwbBonusFSbox02>
            <JwbbfBX01>
              Soft cap to receive
              <ToolTip
                tip={
                  'For Auction PDOs, the soft cap is the minimum investment needed or the auction cancels and the funds are returned.'
                }
                id={'tipSoftCap'}
              />
            </JwbbfBX01>
            <JwbbfBX02>
              <Jwbinput01 className="idoDollrFix01">
                <JwbDropdown>
                  {' '}
                  <i className="idoDollrSign">$</i>
                  {/* <TextBox placeholder='Enter Soft Cap'></TextBox> */}
                  <TextBox
                    value={Value.softCap}
                    onUserInput={handleSoftCapChange}
                    placeholder="Enter Soft Cap"
                  ></TextBox>
                  {/* missing drop down */}
                </JwbDropdown>

                <SmallAlertText className="mt10">
                  You can Put Max ${convertToCurrency(maxCap)} as soft cap{' '}
                </SmallAlertText>
              </Jwbinput01>
            </JwbbfBX02>
          </JwbBonusFSbox02>
        )}
      </JwbBonusFMbox>

      {/* <Collapse isOpened={value.isStaking} > */}
      <JwbBonusFMbox>
        <JwbBonusFSbox01>
          <JwbbfBX011>
            Staking Period{' '}
            <ToolTip
              tip={'Select any time period you wish investors tokens to be locked. Minimum is 1 day'}
              id={'m1'}
            />
          </JwbbfBX011>
          <JwbbfBX02>
            <Jwbinput01>
              <JwbDropdown>
                <TextBox
                  value={Value.poolInfo.period}
                  onUserInput={handlePeriodChange}
                  placeholder="Enter Period"
                ></TextBox>
                <span>Days</span>
              </JwbDropdown>
            </Jwbinput01>
          </JwbbfBX02>
        </JwbBonusFSbox01>
        {Value.poolInfo.period > 0 && (
          <JwbBonusFSbox02>
            <JwbbfBX01>
              APY
              <ToolTip tip={'APY is the rate of return that is earned in one year'} id={'m5'} />
            </JwbbfBX01>
            <JwbbfBX02>
              <Jwbinput01 className="idoDollrFix02">
                <JwbDropdown>
                  {' '}
                  <i className="idoDollrSign02">%</i>
                  {/* <TextBox placeholder='Enter Soft Cap'></TextBox> */}
                  <TextBoxWithDecimal
                    value={Value.poolInfo.apy}
                    onUserInput={handleApyChange}
                    placeholder="Enter APY"
                  ></TextBoxWithDecimal>
                  {/* missing drop down */}
                </JwbDropdown>
                {/* <SmallAlertText className='mt10'>Total supply splits 66.66% allocation  / 33.33% APY</SmallAlertText> */}
              </Jwbinput01>
            </JwbbfBX02>
          </JwbBonusFSbox02>
        )}
      </JwbBonusFMbox>

      <JwbBonusFMbox>
        <JwbBonusFSbox01>
          <JwbbfBX011>
            <JwbbfBX03>
              Dumper Shield <br />
              Protection &nbsp;&nbsp;
              <ToolTip
                tip={`<div>
                                    <div>
                                    The Dumper Shield allows investors access to sell or trade their tokens through a gateway. The tokens behind the Dumper Shield cannot ever be sold below the average market value. Investors can sell their tokens at or above the average market value only. The gateway swaps the tokens using SmartSwap and AMMs. 
                                    </div>
                                    <div>
                                    If an investor wants to sell the tokens for a discount, there is a built-in OTC solution behind the Dumper Shield. The OTC transactions do not affect the tokens value in pools or on exchanges.
                                    </div>
                                </div>`}
                id={'m5'}
              />
            </JwbbfBX03>
          </JwbbfBX011>
          <JwbbfBX02>
            <RadioWrap03 className="tabFix01">
              <div className="md-radio">
                <Input
                  type="radio"
                  id="radio555"
                  name="aaa"
                  value="1"
                  onChange={handleDumperChange}
                  checked={Value.isDumperSheild}
                />
                <Label htmlFor="radio555"> Yes</Label>
              </div>
              <div className="md-radio">
                <Input
                  type="radio"
                  id="radio666"
                  name="aaa"
                  value="0"
                  onChange={handleDumperChange}
                  checked={!Value.isDumperSheild}
                />
                <Label htmlFor="radio666">No</Label>
              </div>
            </RadioWrap03>
          </JwbbfBX02>
        </JwbBonusFSbox01>
        {Value.isDumperSheild && (
          <JwbBonusFSbox02>
            <JwbbfBX01>
              {/* Dumper shield Date  */}
              Choose the dumper shield period
              <ToolTip
                tip={'Select any date you wish. The date is DAO voteable with your token if desired.'}
                id={'m5'}
              />
            </JwbbfBX01>
            {isDsDate ? (
              <JwbbfBX02>
                <Jwbinput01>
                  <JwbDropdown>
                    <TextBox
                      value={formatDate(dsDate)}
                      placeholder={''}
                      onUserInput={() => {}}
                      readOnly={true}
                    ></TextBox>
                    <SmallAlertText className="mt10">Existing dumper shield been find for {name}</SmallAlertText>
                  </JwbDropdown>
                </Jwbinput01>
              </JwbbfBX02>
            ) : (
              <JwbbfBX02>
                <JwbDropdown>
                  <FlyCalICON>
                    <i className="fas fa-calendar-alt"></i>
                  </FlyCalICON>
                  <DatePicker
                    selected={dsDate}
                    minDate={Date.now()}
                    onChange={date => {
                      setDsDate(date)
                      handleDsTimeChange(date)
                    }}
                  />
                </JwbDropdown>
              </JwbbfBX02>
            )}
            {/* <Jwbinput01 className="idoDollrFix02">
                                <JwbDropdown className='v2'>  <i className="idoDollrSign02" style={{ height: "52px" }}>Days</i>

                                    <TextBox value={Value.dumperShedilDay} onUserInput={handleDumperDaysChange} placeholder='Enter the dumper shield Days '></TextBox>

                                </JwbDropdown>
                                <SmallAlertText className='mt10'>Existing dumper shield been find for that token</SmallAlertText>
                            </Jwbinput01> */}
          </JwbBonusFSbox02>
        )}
      </JwbBonusFMbox>

      {/* </Collapse> */}
      <div style={{ height: '45px' }}></div>

      {/* <JwbBonusFMbox02 >
                <JwbBonusFSbox011>
                    <JwbbfBX03>
                        Secure Future <ToolTip tip={"help text"} id={"m5"} />
                    </JwbbfBX03>
                    <JwbbfBX03>
                        <RadioWrap03>
                            <div className="md-radio w-auto">
                                <Input type="radio" id="radio1371" checked name="secure" value="1" />
                                <Label htmlFor="radio1371"> Yes
                                </Label>
                            </div>
                            <div className="md-radio w-auto">
                                <Input type="radio" id="radio1381" checked name="secure" value="0" />
                                <Label htmlFor='radio1381'>No</Label>
                            </div>
                        </RadioWrap03>
                    </JwbbfBX03>
                </JwbBonusFSbox011>
            </JwbBonusFMbox02> */}

      <JwbBonusFMbox02>
        <JwbBonusFSbox011>
          <JwbbfBX03>
            Fair PDO <ToolTip tip={'Burn leftover tokens at the end of the PDO'} id={'m5'} />
          </JwbbfBX03>
          <JwbbfBX03>
            <RadioWrap03>
              <div className="md-radio w-auto">
                <Input
                  type="radio"
                  id="radio137"
                  checked={Value.fairPdo}
                  name="fair"
                  value="1"
                  onChange={handleFairPdoChange}
                />
                <Label htmlFor="radio137"> Yes</Label>
              </div>
              <div className="md-radio w-auto">
                <Input
                  type="radio"
                  id="radio138"
                  checked={!Value.fairPdo}
                  name="fair"
                  value="0"
                  onChange={handleFairPdoChange}
                />
                <Label htmlFor="radio138">No</Label>
              </div>
            </RadioWrap03>
          </JwbbfBX03>
        </JwbBonusFSbox011>
      </JwbBonusFMbox02>

      <JwbBonusFMbox02>
        <JwbBonusFSbox011>
          <JwbbfBX03>
            KYC/AML required?{' '}
            <ToolTip
              tip={
                'Users can select to perform KYC / AML before deploying funds or they can complete Reverse KYC / AML which allows investors to deploy funds first then complete the KYC / AML before claiming.'
              }
              id={'m5'}
            />
          </JwbbfBX03>
          <JwbbfBX03>
            <RadioWrap03>
              <div className="md-radio w-auto">
                <Input
                  type="radio"
                  id="radioKyc137"
                  name="kyc"
                  value="1"
                  checked={Value.investInfo.isKyc}
                  onChange={handleKycChange}
                />
                <Label htmlFor="radioKyc137"> Yes</Label>
              </div>
              <div className="md-radio w-auto">
                <Input
                  type="radio"
                  id="radioKyc138"
                  name="kyc"
                  value="0"
                  checked={!Value.investInfo.isKyc}
                  onChange={handleKycChange}
                />
                <Label htmlFor="radioKyc138">No</Label>
              </div>
            </RadioWrap03>
          </JwbbfBX03>
        </JwbBonusFSbox011>
        {/* {isKyc &&
                    <JwbBonusFSbox02>
                        <React.Fragment>
                            <JwbbfBX03>
                                Choose the minimum amount required for KYC/AML
                            </JwbbfBX03>
                            <JwbbfBX03>
                                <Jwbinput01 style={{ maxWidth: 'none', marginTop: '8px' }} className="idoDollrFix01">
                                    <JwbDropdown className='v3'>  <i className="idoDollrSign">$</i>
                                        <TextBox value={0} onUserInput={handleMinimumInvestmentChange} placeholder='Please enter minimum amount for KYC/AML'></TextBox>
                                    </JwbDropdown>
                                </Jwbinput01>
                            </JwbbfBX03>
                        </React.Fragment>
                    </JwbBonusFSbox02>
                } */}
      </JwbBonusFMbox02>

      <JwbBonusFMbox02 className="v2">
        <JwbBonusFSbox011>
          <JwbbfBX03>Investment limit per wallet</JwbbfBX03>
          <JwbbfBX03>
            <RadioWrap03>
              <div className="md-radio w-auto">
                <Input
                  type="radio"
                  id="radio01"
                  name="limit"
                  onChange={handleLimitChange}
                  value="0"
                  checked={Value.investInfo.limitType === 0}
                />
                <Label htmlFor="radio01"> No Limit</Label>
              </div>
              <div className="md-radio w-auto">
                <Input
                  type="radio"
                  id="radio02"
                  name="limit"
                  onChange={handleLimitChange}
                  value="1"
                  checked={Value.investInfo.limitType === 1}
                />
                <Label htmlFor="radio02">Token Limit</Label>
              </div>
              <div className="md-radio w-auto">
                <Input
                  type="radio"
                  id="radio03"
                  name="limit"
                  onChange={handleLimitChange}
                  value="2"
                  checked={Value.investInfo.limitType === 2}
                />
                <Label htmlFor="radio03">USD Limit</Label>
              </div>
              {/* <div className="md-radio w-auto" >
                                <Input type="radio" id="radio01" name="limit" value="0" checked />
                                <Label htmlFor="radio01"> Yes
                                </Label>
                            </div>
                            <div className="md-radio w-auto">
                                <Input type="radio" id="radio02" name="limit" value="1" checked />
                                <Label htmlFor='radio02'>No</Label>
                            </div> */}
            </RadioWrap03>
          </JwbbfBX03>
        </JwbBonusFSbox011>
        {Value.investInfo.limitType > 0 && (
          <JwbBonusFSbox02>
            <React.Fragment>
              <JwbbfBX03>
                Enter Minimum and Maximum {Value.investInfo.limitType === 1 ? 'Token' : 'Usd'} Amount
              </JwbbfBX03>

              <JwbbfBX03>
                <Jwbinput01
                  style={{ maxWidth: '306px', marginTop: '8px', marginLeft: '0px' }}
                  className={Value.investInfo.limitType === 2 && 'idoDollrFix01'}
                >
                  <JwbDropdown>
                    {Value.investInfo.limitType === 2 && <i className="idoDollrSign">$</i>}

                    <TextBoxWithDecimal
                      value={Value.investInfo.minInvest}
                      onUserInput={handleMinimumInvestmentChange}
                      placeholder="Please enter minimum amount for KYC/AML"
                    ></TextBoxWithDecimal>
                  </JwbDropdown>
                </Jwbinput01>

                <Jwbinput01
                  style={{ maxWidth: '306px', marginTop: '8px' }}
                  className={Value.investInfo.limitType === 2 && 'idoDollrFix01'}
                >
                  <JwbDropdown>
                    {Value.investInfo.limitType === 2 && <i className="idoDollrSign">$</i>}
                    <TextBoxWithDecimal
                      value={Value.investInfo.maxInvest}
                      onUserInput={handleMaximumInvestmentChange}
                      placeholder="Please enter minimum amount for KYC/AML"
                    ></TextBoxWithDecimal>
                  </JwbDropdown>
                </Jwbinput01>
              </JwbbfBX03>
            </React.Fragment>
          </JwbBonusFSbox02>
        )}
        {/* <JwbBonusFSbox02>
                    <React.Fragment>
                        <JwbbfBX03 className='v2'>
                            <div className="md-radio" style={{ width: "30px" }}>
                                <Input type="radio" id="radio0123" name="minmax1" value="1" checked />
                                <Label htmlFor="radio0123"></Label>
                            </div>
                            <div className='two-section'>
                                <p>Minimum</p>
                                <JwbDropdown className='v2 v4'>
                                    <TextBox placeholder='' value={''} onUserInput={()=>{}}></TextBox>
                                    <span className='set-left'>$</span>
                                </JwbDropdown>
                            </div>
                            <div className='two-section'>
                                <p>Maximum</p>
                                <JwbDropdown className='v2 v4'>
                                    <TextBox placeholder='' value={''} onUserInput={()=>{}}></TextBox>
                                    <span className='set-left'>$</span>
                                </JwbDropdown>
                            </div>
                        </JwbbfBX03>
                        <JwbbfBX03 className='v2'>
                            <div className="md-radio" style={{ width: "30px" }}>
                                <Input type="radio" id="radio0124" name="minmax1" value="0" checked />
                                <Label htmlFor="radio0124"></Label>
                            </div>
                            <div className='two-section'>
                                <p>Minimum</p>
                                <JwbDropdown className='v2'>
                                    <TextBox placeholder='' value={''} onUserInput={()=>{}}></TextBox>
                                    <span>TOKEN</span>
                                </JwbDropdown>
                            </div>
                            <div className='two-section'>
                                <p>Maximum</p>
                                <JwbDropdown className='v2'>
                                    <TextBox placeholder='' value={''} onUserInput={()=>{}}></TextBox>
                                    <span>TOKEN</span>
                                </JwbDropdown>
                            </div>
                        </JwbbfBX03>
                    </React.Fragment>
                </JwbBonusFSbox02> */}
      </JwbBonusFMbox02>

      {/* <JwbBonusFMbox02 >
            <JwbBonusFSbox011>
                <JwbbfBX03>
                Buy-back approach 
                </JwbbfBX03>
                <JwbbfBX03>
                    <RadioWrap03>
                        <div className="md-radio" >
                            <Input type="radio" id="radio03" name="aaa" value="1" checked  />
                            <Label htmlFor="radio03"> Inject funds and burn tokens <ToolTip tip={"ComingSoon"} id={"tipmininv"} />
                        </Label>
                        </div> 
                    </RadioWrap03>
                </JwbbfBX03>
            </JwbBonusFSbox011>
            <JwbBonusFSbox02> 
                    <React.Fragment>
                        <JwbbfBX03>
                            
                        </JwbbfBX03> 
                        <JwbbfBX03>
                        <RadioWrap03> 
                        <div className="md-radio">
                            <Input type="radio" id="radio04" name="aaa" value="0"    />
                            <Label htmlFor='radio04'>Inject fund only <ToolTip tip={"ComingSoon"} id={"tipmininv"} />
                            </Label>
                        </div>
                    </RadioWrap03>
                        
                        </JwbbfBX03>
                    </React.Fragment>
                

            </JwbBonusFSbox02>
        </JwbBonusFMbox02> */}

      <JwbBonusFMbox02>
        <JwbBonusFSbox011>
          <JwbbfBX03>
            Liquidity protection
            <ToolTip
              tip={` <div>In order to open a PDO you must have a liquidity pool on an AMM platform. The maximum amount of funds that you are allowed to withdraw for operations must be less than the total liquidity in the pool.</div>`}
              id={'liquidityProtectionTip'}
            />
          </JwbbfBX03>
          <JwbbfBX03>
            <RadioWrap02>
              <div className="md-radio">
                <Input
                  type="radio"
                  id="stop"
                  checked={Value.investInfo.liquidityProtection === 1}
                  name="liquidityProduction"
                  value="1"
                  onChange={handleOnPauseLiquidity}
                />
                <Label htmlFor="stop">
                  Continue PDO and automatically allocate funds from fundraising to maintain the required 2:1 minimum
                  liquidity.
                  <ToolTip
                    tip={`<div>If a pool has a total liquidity of $100,000 you are allowed to withdraw no more than $50k.</div>
                                <div>Once the max withdraw is reached, the fundraising goes to increase the pools.</div>`}
                    id={'tipmininv1'}
                  />
                </Label>
              </div>
            </RadioWrap02>
          </JwbbfBX03>
        </JwbBonusFSbox011>

        <JwbBonusFSbox02>
          <JwbbfBX03></JwbbfBX03>
          <JwbbfBX03></JwbbfBX03>
          <JwbbfBX03>
            <RadioWrap02>
              <div className="md-radio">
                <Input
                  type="radio"
                  id="continue"
                  checked={Value.investInfo.liquidityProtection === 0}
                  name="liquidityProduction"
                  value="0"
                  onChange={handleOnPauseLiquidity}
                />
                <Label htmlFor="continue">
                  Stop PDO if liquidity pools are less than 200% of the actual fundraising
                  <ToolTip
                    tip={`<div>If a pool has a total liquidity of $100,000 you are allowed to withdraw no more than $50k.</div>
                                <div>Once the max withdraw is reached, the fundraising is stopped.</div>`}
                    id={'tipmininv2'}
                  />
                </Label>
              </div>
            </RadioWrap02>
          </JwbbfBX03>
        </JwbBonusFSbox02>
      </JwbBonusFMbox02>
      <JwbCBTNBar02>
        {!isAddress(Value.poolInfo.offeringToken || approvalFactory === ApprovalState.UNKNOWN) ? (
          <JwbConnectBTN onClick={showErrorMessage}>
            {approvalFactory === ApprovalState.APPROVED ? 'Save' : 'NEXT STEP'}
          </JwbConnectBTN>
        ) : approvalFactory === ApprovalState.NOT_APPROVED ? (
          // isAddress(value.offeringToken)?(
          <JwbConnectBTN onClick={approveFactoryCallback}>Approve Setup</JwbConnectBTN>
        ) : approvalFactory === ApprovalState.PENDING ? (
          <DisableButton>
            <i className="fas fa-spinner fa-spin"></i> Approving
          </DisableButton>
        ) : approvalFactory === ApprovalState.APPROVED ? (
          <JwbConnectBTNorangeBack onClick={checkAndValidate}>SAVE</JwbConnectBTNorangeBack>
        ) : (
          <JwbConnectBTN onClick={showErrorMessage}>Approve Setup</JwbConnectBTN>
        )}
        {/* <JwbConnectBTNorangeBack onClick = {moveToTab} >SAVE</JwbConnectBTNorangeBack> */}
      </JwbCBTNBar02>
      {/* <JwbBonusFMTitle03 style={{ textAlign: 'center' }}>
            In order to launch an PDO you must have on bSWAP a pool between your BEP20 token and BNB with at least $${numberWithCommas(factoryValues.minimumLiquidityRequired)} total liquidity
        </JwbBonusFMTitle03> */}
    </JwbBonusFormMbox>
  )
}

const JwbConnectBTN = styled.a`
  background: none;
  width: 100%;
  max-width: 670px;
  padding: 26px 50px;
  display: inline-block;
  font: 700 30px/28px 'IBM Plex Mono',monospace;
  color: #fff;
  text-align: center;
  border-radius: 10px;
  border: 2px solid #00f02b;
  box-shadow: 0 0 15px #00f02b;
  cursor: pointer;
  &:hover {background: #00f02b; color: #fff}
  ${Media.xs} {
    padding: 15px 10px;
    font: normal 18px/40px 'Press Start 2P', arial, sans-serif;
  }
`
const DisableButton = styled.a`
  background-color: ${({ theme }) => theme.ConnectButtonBG};
  width: 100%;
  max-width: 670px;
  padding: 27px 50px;
  display: inline-block;
  font: 600 30px/28px 'Kanit', arial, sans-serif;
  color: #00d426;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
`
const Circle = styled.div`
  font-size: 13px;
  position: relative;
  top: -20px;
  width: auto !important;
  right: -5px;
`
const JwbConnectBTNorangeBack = styled.a`
  background-color: #00d426;
  width: 100%;
  max-width: 670px;
  padding: 27px 50px;
  text-align: center;
  border-radius: 10px;
  display: inline-block;
  font: 600 30px/28px 'Kanit', arial, sans-serif;
`
const JwbCBTNBar02 = styled.div`
  padding: 20px 30px;
  text-align: center;
  ${Media.xs} {
    padding: 20px 5px;
  }
`
// const Fasfainfocircle = styled.i`
//     font-weight: 900;
//     font-family: "Font Awesome 5 Free";
//     -webkit-font-smoothing: antialiased;
//     display: inline-block;
//     font-style: normal;
//     font-variant: normal;
//     text-rendering: auto;
//     line-height: 1;
//     &:before {
//         content: "\f05a";
//     }
// `

const JwbBonusFMTitle03 = styled.div`
  font-weight: 400;
  text-align: left;
  padding-left: 10px;
  width: 100%;
  color: #c32b1c;
  margin: 0px 0 10px 0;
  font-size: 12px;
`
const RadioWrap02 = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 0;
  padding-top: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
`
const RadioWrap03 = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding-top: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  label {
    font-weight: normal !important;
  }
  .md-radio {
    width: 50%;
    text-align: left;
    &.w-auto {
      width: auto;
    }
  }
`
// const Divs = styled.div`
//     margin-bottom: 20px;
//     margin: 0px;
//     padding: 0 4px;
// `
const JwbInput01WithICO02FullWidth = styled.div<{ name?: string }>`
    font-weight: 700;
    font-size: 16px;
    position: relative;
    margin-left: auto;
    width: 100%;
    max-width: none;
    &:after
    {
        content: '${({ name }) => name}';
        bottom: 0px;
        color:${({ theme }) => theme.ifoText2};
        /* background-color: ${({ theme }) => theme.ifoText1}; */
        background-color:#5a5e67;
        position: absolute;
        right: 0;
        top: 0;
        border-radius: 0 10px 10px 0;
        padding: 20px 20px;
    }

`
const JwbBonusFMbox02AlignTop = styled.div`
  align-items: flex-start;
  justify-content: left;
  flex-wrap: wrap;
  margin-bottom: 30px;
  display: flex;
`
const JwbBonusFMTitle01 = styled.div`
  font-size: 16px;
  font-weight: 700;
  margin: 10px 0;
  text-align: left;
  width: 100%;
  min-height: 35px;
  color: ${({ theme }) => theme.ifoText1};
`
// const JwbBonusFMbox02alignTop = styled.div`
//     align-items: flex-start;
//     justify-content: center;
//     flex-wrap: wrap;
//     margin-bottom: 30px;
//     display: flex;
// `
const JwbBonusFSbox011 = styled.div`
  width: 50%;
  padding: 0 30px 0 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  ${Media.md} {
    width: 100%;
    padding: 0 15px 15px 15px;
  }
`
const JwbDropdown = styled.div`
  width: 100%;
  font-weight: 700;
  font-size: 16px;
  position: relative;
  max-width: 380px;
  input {
    border: 2px solid ${({ theme }) => theme.headerImageBg};
    background-color: transparent;
  }
  &.v2 {
    input {
      background-color: transparent;
    }
  }
  &.v3 {
    max-width: 100%;
  }
  &.v4 {
    input {
      padding-left: 80px;
    }
  }
  span {
    position: absolute;
    top: 2px;
    right: 2px;
    background-color: #5a5e67;
    height: 50px;
    padding: 0px 30px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    &.v2 {
      height: 52px;
      padding: 0px 10px;
    }
    &.set-left {
      left: 2px;
      right: auto;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
  }
  .react-datepicker__input-container input {
    font-weight: normal;
  }
`
const FlyCalICON = styled.div`
  color: #fff;
  position: absolute;
  right: 14px;
  top: 17px;
`
const Jwbinput01 = styled.div`
  width: 100%;
  max-width: 380px;
  font-weight: 700;
  font-size: 16px;
  position: relative;
  // margin-left: auto;
  @media (max-width: 500px) and (min-width: 320px) {
    max-width: 500px;
  }
  @media (max-width: 767px) and (min-width: 501px) {
    max-width: 100% !important;
  }
`
const JwbBonusFMbox = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: left;
  flex-wrap: wrap;
`
const JwbbfBX011 = styled.div`
  color: ${({ theme }) => theme.ifoText1};
  width: 40%;
  font-size: 16px;
  font-weight: 700;
  position: relative;
  line-height: 24px;
  letter-spacing: -0.5px;
  ${Media.sm} {
    width: 100%;
  }
`
const JwbBonusFSbox02 = styled.div`
  width: 50%;
  padding: 0 0 0 30px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  ${Media.md} {
    width: 100%;
    padding: 0 15px 15px 15px;
  }
  ${Media.xs} {
    padding: 0 5px 15px 5px;
  }
`
// const Circle = styled.i`
//     font-size: 13px;
//     position: relative;
//     top: -11px;
//     width: auto!important;
// `
// const HelpCircles = styled.i`
//     font-size: 12px;
//     font-weight: 900;
//     font-family: "Font Awesome 5 Free";
//     -webkit-font-smoothing: antialiased;
//     display: inline-block;
//     font-style: normal;
//     font-variant: normal;
//     text-rendering: auto;
//     line-height: 1;
//     &:before{
//         content: "\f059"
//     }
//     &:hover{
//         color:#faba37
//     }
// `
const JwbBonusFormMbox = styled.div`
  /* background-color: #060606; */
  /* border-bottom: 1px solid #393d46; */
  border: 0px;
  padding: 30px 0 40px 0;
  margin-bottom: 22px;
  position: relative;
  border-radius: 10px;
`
const JwbBonusFMbox02 = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 30px;
  &.v2 {
    align-items: flex-start;
  }
`
const JwbBonusFSbox01 = styled.div`
  width: 50%;
  padding: 0 30px 0 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  ${Media.md} {
    width: 100%;
    padding: 0 15px 15px 15px;
  }
  ${Media.md} {
    width: 100%;
    padding: 0 5px 15px 5px;
  }
`
const JwbbfBX01 = styled.div`
  color: ${({ theme }) => theme.ifoText1};
  /* width:60%; */
  width: 40%;
  font-size: 16px;
  font-weight: 700;
  position: relative;
  line-height: 24px;
  letter-spacing: -0.5px;
  ${Media.sm} {
    width: 100%;
  }
`
const JwbbfBX02 = styled.div`
  color: ${({ theme }) => theme.ifoText1};
  width: 60%;
  text-align: right;
  position: relative;
  color: ${({ theme }) => theme.ifoText1};
  ${Media.sm} {
    width: 100%;
    text-align: left;
  }
`
const JwbbfBX03 = styled.div`
  color: ${({ theme }) => theme.ifoText1};
  width: 100%;
  text-align: left;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${Media.sm} {
    flex-wrap: wrap;
  }
  .two-section {
    width: calc(50% - 40px);
    margin-bottom: 30px;
    p {
      margin: 0px 0px 20px;
    }
  }
  &.v2 {
    justify-content: space-between;
    .md-radio label:before {
      top: -10px !important;
    }
    .md-radio label:after {
      top: -5px !important;
    }
  }
`
const RadioWrap = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0;
  padding-top: 10px;
  align-items: flex-start;
  justify-content: flex-end;
  width: 100%;
  @media (max-width: 500px) and (min-width: 320px) {
    margin: 0;
    justify-content: flex-start;
    width: 100%;
    .md-radio {
      width: 100%;
    }
  }
  @media (max-width: 767px) and (min-width: 501px) {
    justify-content: flex-start;
    width: 100%;
  }
  @media (max-width: 991px) and (min-width: 768px) {
    margin: 0;
  }
`
const div = styled.div`
  padding: 0 4px;
  margin: 16px 0;
`
const Input = styled.input`
  border-radius: 4px;
  /* font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  color: ${({ theme }) => theme.ifoText1}000;
  font-family: 'IBM Plex Mono', monospace; */
`
const Label = styled.label`
  color: ${({ theme }) => theme.ifoText1};
  text-transform: none;
  font-weight: 700 !important;
  font-size: 16px !important;
  /* color: ${({ theme }) => theme.ifoText1}; */
  /* text-transform: uppercase; */
  padding-left: 27px !important;
  &:before
  {
      left: 0 !important;
      top: 3px !important;
      width: 20px !important;
      height: 20px !important;
      border: 2px solid ${({ theme }) => theme.headerImageBg} !important;
      background: rgba(255, 255, 255, 0.05);
  }
  &:after{ background: #00f02b !important; } 
  ${Media.xs}{width:100% !important;}
`
const DDNewMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 380px;
  position: relative;
  height: 52px;
  border: 2px solid #5a5e67;
  border-radius: 10px;
  .fas {
    margin-left: auto;
    margin-right: 5px;
  }
  ${Media.sm} {
    max-width: 100%;
  }
`
const DDBTN01 = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 16px;
  color: #fff;
  font-weight: normal;
  border: none;
  background-color: transparent;
  outline: none;
  width: 100%;
`
const DDTokenIMG = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 16px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  margin-left: 6px;
  & img {
    max-width: 100%;
    height: auto;
  }
`
const DDTokenIMG02 = styled.div`
  margin: auto 6px;
  display: flex;
  & span {
    width: 30px;
    height: 30px;
    border-radius: 16px;
    background-color: #fff;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto 4px;
  }
  & img {
    max-width: 100%;
    height: auto;
  }
`
const DDdropDown = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  top: calc(100% + -4px);
  padding: 6px;
  background-color: rgba(0, 0, 0, 0.9);
  max-height: 30vh;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 10;
  border-radius: 10px;
  visibility: hidden;
  opacity: 0;
  transition: 500ms;
  border: 2px solid #5a5e67;
  border-radius: 10px;
  border-top: none;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;

  & ${DDBTN01} {
    margin: 15px 0;
    :hover {
      opacity: 0.8;
    }
  }
  ${DDNewMenu}.open & {
    visibility: visible;
    opacity: 1;
  }
`
const SmallAlertText = styled.div`
  font-size: 11px;
  color: #c32b1c;
  line-height: 12px;
  font-weight: 400;
  & a {
    color: #c32b1c;
    :hover {
      opacity: 0.7;
    }
  }
  &.mt10 {
    margin-top: 10px;
  }
`
